/* eslint-disable no-undef */ 
const appConstants = {
    // apiBaseURL: "https://fundzapi.demoninja.in/",   // development
    // apiBaseURL: "http://13.201.174.173:3001/",      // Live: IP
    apiBaseURL: "http://13.127.110.149:3001/",      // Dev: IP
    // apiBaseURL: "https://api.fundzup.in/",          // Live: domain
  
    localStorage: {
      tokenKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo4MDAsImVtYWlsIjoiYWRtaW5AZnVuZHp1cC5jb20iLCJtb2JpbGUiOiI5ODk4OTg5ODk4Iiwicm9sZV9pZCI6MSwiam9pbmluZ19kYXRlIjoiMjAyNC0wMy0zMVQxODozMDowMC4wMDBaIiwiaXNfbW9iaWxlX3ZlcmlmaWVkIjoiWSIsImlzX2VtYWlsX3ZlcmlmaWVkIjoiWSIsImlhdCI6MTcxMjAzMDM2OCwiZXhwIjoxNzEyMTE2NzY4fQ.0XmxDeLKbfnz34npnrW41WzbriNcLGwp0qmuOfgtoF8',
      userNameKey: 'userName'
    },

    
    // user role Type
    userRoles:{
      ADMIN:{ 
        id:1,
        code: "ADMIN", 
        entryRoute:"admin/dashboard"
      },
      RIA:{ 
        id:2,
        code: "RIA", 
        entryRoute:"/dashboard"
      },
      SUBBROKER:{ 
        id:3,
        code: "SUBBROKER", 
        entryRoute:"/dashboard"
      },
      CUSTOMER:{ 
        id:4,
        code: "CUSTOMER", 
        entryRoute:"customer/dashboard"
      },
    }

  };
  
  export { appConstants };
