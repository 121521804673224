import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { validationHelper } from "helpers/validation";
import CustomerDetails from "components/addCustomer/CustomerDetails";
import CustomerRiskDetails from "components/addCustomer/CustomerRiskDetails";
import { addCustomerApiAction } from "stores/redux/apiSlices/addCustomerApiSlice";
import { addCustomerActions } from "stores/redux/slices/addCustomerSlice";
import useOtpTimer from "hooks/OtpTimer";
import { useLocation } from "react-router-dom";

import _get from "lodash/get";

// This is Customer Detail Container
const CustomerDetailsContainer = ({
  handleStepChange,
  currentStep,
  currentUserId,
  qtsOptions,
}) => {
  console.log("bebebebe", qtsOptions);
  //Basic Details states
  const [basicDetails, setBasicDetails] = useState({
    name: "",
    email: "",
    phoneNo: "",
    emailOtp: "",
    phoneOtp: "",
  });
  const [basicDetailsError, setBasicDetailsError] = useState({
    email: "",
    phoneNo: "",
    emailOtp: "",
    phoneOtp: "",
  });
  const [isEmailOtpSend, setIsEmailOtpSend] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isPhoneOtpSend, setIsPhoneOtpSend] = useState(false);
  const [isBasicDetailsCompleted, setIsBasicDetailsCompleted] = useState(false);
  const [userId, setUserId] = useState(null);

  // const [isRiskProfileSelected, setIsRiskProfileSelected] = useState(true);

  // Advance Details states.
  const [riskDetailsAnswer, setRiskDetailsAnswer] = useState([]);
  const [currentQtsId, setCurrentQtsId] = useState(1);
  const [totalQts, setTotalQts] = useState(0);
  const [riskPoints, setRiskPoints] = useState(0);

  //hooks
  const {
    seconds: emailSec,
    isActive: emailOtpActive,
    startTimer: startEmailTimer,
    resetTimer: resetEmailTimer,
  } = useOtpTimer();

  const {
    seconds: phoneSec,
    isActive: phoneOtpActive,
    startTimer: startPhoneTimer,
    resetTimer: resetPhoneTimer,
  } = useOtpTimer();

  // Add Customer Actions
  const { data: riskAssesmentQts } = addCustomerApiAction.getRiskAssesment();
  console.log("riskAssesmentQtsssssss", riskAssesmentQts);
  const [previousAnswer, { isFetching, isLoading: isPrevAnsApiLoading }] =
    addCustomerApiAction.getPreviousAnswer();
  // console.log("selectedPreviousAnswer",selectedPreviousAnswer)

  const [sendOTPAction] = addCustomerApiAction.sendOtp();
  const [confirmEmailOtpAction] = addCustomerApiAction.confirmEmailOtp();
  const [confirmPhoneOtpAction] = addCustomerApiAction.confirmPhoneOtp();
  const [submitRiskDetailsAction] = addCustomerApiAction.submitRiskDetails();
  const dispatch = useDispatch();
  const location = useLocation();
  const initialOnBoardData = location.state && location.state.onBoardData;
  console.log("initialOnBoardData", initialOnBoardData);
  const [onBoardData, setOnBoardData] = useState({
    user_id: initialOnBoardData?.user_id,
    name: initialOnBoardData?.name || "",
    email: initialOnBoardData?.email || "",
    mobile: initialOnBoardData?.mobile || "",
    is_email_verified: initialOnBoardData?.is_email_verified || "",
    is_mobile_verified: initialOnBoardData?.is_mobile_verified || "",
    current_stage: initialOnBoardData?.current_stage || "",
    emailOtp: "",
    phoneOtp: "",
  });
  const [onBoardDataError, setOnBoardDataError] = useState({
    emailOtp: "",
    phoneOtp: "",
  });
  const [isEmailOnboardVerified, setIsEmailOnboardVerified] = useState(
    onBoardData?.is_email_verified === "Y"
  );

  //useEffect

  useEffect(() => {}, [onBoardData]);

  useEffect(() => {
    if (emailSec === 0) {
      resetEmailTimer();
    }
  }, [emailSec]);

  useEffect(() => {
    if (phoneSec === 0) {
      resetPhoneTimer();
    }
  }, [phoneSec]);

  useEffect(() => {
    initSetAnser();
    if (currentUserId) {
      setUserId(currentUserId);
    }
  }, []);

  const initSetAnser = async () => {
    if (currentUserId) {
      const selectedAnswer = await previousAnswer({ userId: currentUserId }, false);
      const answers = _get(selectedAnswer, "data", []);
      
      console.log("answerssssss", answers);
      
      if (answers && answers.length) {
        setIsBasicDetailsCompleted(true);
        const persedSelectedAns = answers.map((d) => {
          return {
            question_id: d.question_id,
            answersIds: d.answersIds
          };
        });
  
        console.log("persedSelectedAns", persedSelectedAns);

        setCurrentQtsId(persedSelectedAns.length);
        setRiskDetailsAnswer(persedSelectedAns);

        let point = 0;
        persedSelectedAns.map( ans => {
          if(ans && ans.answersIds && ans.answersIds.length){
            ans.answersIds.map( p => {
              point += p.weightage;
            })
          }
        });
        setRiskPoints(point);
      }
    }
  };
  

  useEffect(() => {
    if (riskAssesmentQts) {
      setTotalQts(riskAssesmentQts.qts.length);
    }
  }, [riskAssesmentQts]);

  // Form Handle Function
  const handleBasicFormChange = (name, value) => {
    const newBasicDetails = { ...basicDetails };
    if (name === "phoneNo" || name === "emailOtp" || name === "phoneOtp") {
      if (isNaN(value)) {
        return;
      }
    }
    setBasicDetails({ ...newBasicDetails, [name]: value });
  };

  const handleEmailValidation = () => {
    let newError = { ...basicDetailsError };
    const emailValidation = validationHelper.email(basicDetails.email);
    newError.email = emailValidation.message;
    setBasicDetailsError(newError);
    return emailValidation.isValid;
  };

  const handleEmailOtpSend = async () => {
    if (!handleEmailValidation()) {
      return;
    }
    const payload = {
      name: basicDetails.name,
      type: "email",
      email: basicDetails.email,
      mobile: "",
      user_type: "customer",
      user_id: "",
    };
    try {
      const res = await sendOTPAction(payload).unwrap();
      console.log("res : ", res.result);
      if (res.status === 1) {
        if (res?.result?.current_stage) {
          if (res?.result?.current_stage === 1) {
            setUserId(res?.result?.user_id);
            dispatch(
              addCustomerActions.setCustomerUserId({
                userId: res?.result?.user_id,
              })
            );
            setIsBasicDetailsCompleted(true);
          } else {
            handleStepChange(
              Number(res.result.current_stage + 1),
              res?.result?.user_id
            );
          }
          return;
        }
        setIsEmailOtpSend(true);
        startEmailTimer(5);
      }
    } catch (err) {
      console.log("err:", err);
    }
  };

  const handlePhoneValidation = () => {
    let newError = { ...basicDetailsError };
    const mobileValidation = validationHelper.mobile(basicDetails.phoneNo);
    newError.phoneNo = mobileValidation.message;
    setBasicDetailsError(newError);
    return mobileValidation.isValid;
  };

  const handlePhoneOtpSend = async () => {
    if (!handlePhoneValidation()) {
      return;
    }
    const payload = {
      name: basicDetails?.name,
      type: "mobile",
      email: basicDetails?.email,
      mobile: basicDetails.phoneNo,
      user_type: "customer",
      user_id: userId,
    };
    try {
      const res = await sendOTPAction(payload).unwrap();
      if (res.status === 1) {
        setIsPhoneOtpSend(true);
        startPhoneTimer();
      }
    } catch (err) {
      console.log("err:", err);
    }
  };

  const autoScrollToCurrentQues = (qId = 1) => {
    if (!qId) {
      return;
    }
    const elem = document.getElementById(`ques-ref-${qId}`);
    if (elem) {
      elem.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleRiskDetailAnswers = (
    {
      isComplete,
      answers
    }

  ) => {
    // debugger;
    const {
      question_id,
      answersIds
    }= answers;

    autoScrollToCurrentQues(question_id);
    let newRiskDetails = [...riskDetailsAnswer];
    newRiskDetails = riskDetailsAnswer.filter(
      (item) => item.question_id !== question_id
    );
  
    newRiskDetails.push(answers);
  
    // const point = newRiskDetails.reduce(
    //   (acc, currValue) => acc + currValue.weightage,
    //   0
    // );

    let point = 0;
    newRiskDetails.map( ans => {
      if(ans && ans.answersIds && ans.answersIds.length){
        ans.answersIds.map( p => {
          point += p.weightage;
        })
      }
    });
  
    setRiskDetailsAnswer(newRiskDetails);
    setRiskPoints(point);
  
    if (isComplete) {
      handleSubmit(newRiskDetails);
    } else {
      setCurrentQtsId(currentQtsId + 1);
    }
  };



  const JumpToPrevQuestion = (questionId) => {
    setCurrentQtsId(questionId);
    autoScrollToCurrentQues(questionId - 2);
  };

  const handleRiskDetailPreviousStep = () => {
    autoScrollToCurrentQues(currentQtsId - 2);
    setCurrentQtsId(currentQtsId - 1);
  };

  // Submit Functions
  const otpValidation = ({ name, value }) => {
    let newBasicDetailsError = { ...basicDetailsError };
    const otpValidation = validationHelper.otp(value);
    newBasicDetailsError[name] = otpValidation.message;
    setBasicDetailsError(newBasicDetailsError);
    return otpValidation.isValid;
  };

  const handleEmailOtpSubmit = async () => {
    if (!otpValidation({ name: "emailOtp", value: basicDetails.emailOtp })) {
      return;
    }
    const payload = {
      type: "email",
      email: basicDetails.email,
      mobile: "",
      otp: basicDetails.emailOtp,
    };
    try {
      let res = await confirmEmailOtpAction(payload);
      res = res.data;
      if (res.status === 1) {
        dispatch(
          addCustomerActions.setCustomerUserId({ userId: res.result.user_id })
        );
        setUserId(res.result.user_id);
        setIsEmailVerified(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handlePhoneOtpSubmit = async () => {
    if (!otpValidation({ name: "phoneOtp", value: basicDetails.phoneOtp })) {
      return;
    }
    const payload = {
      type: "mobile",
      email: "",
      mobile: basicDetails?.phoneNo,
      otp: basicDetails.phoneOtp,
      user_id: userId,
    };
    try {
      const res = await confirmPhoneOtpAction(payload);
      if (res.data.status === 1) {
        setIsBasicDetailsCompleted(true);
        // handleStepChange(Number(res.result.current_stage+1));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async (riskDetails) => {
    console.log("riskDetails => ",riskDetails);

    const newRiskDetails = riskDetails?.map((item) => {

      const parseTextVal = (text_values) => text_values.map( txt_val => ({
        option_text_value: txt_val.option_text_value,
        question_textbox_info_id: txt_val.question_textbox_info_id
      }));

      const answersIds = item.answersIds.map( ans => {
        return {
          answer_id: ans.answer_id,
          weightage: ans.weightage,
          text_values: parseTextVal(ans.text_values)
        }
      })

      return {
        question_id: item.question_id,
        answersIds: answersIds,
      }
    });
  
    const payload = {
      userId,
      answers: newRiskDetails,
    };
    console.log("payloaddddddddddd", payload);
    
    try {
      const res = await submitRiskDetailsAction(payload);
      if (res && res.data && res.data.status === 1) {
        handleStepChange(2, userId);
        return null;
      }
    } catch (err) {
      console.log(err);
    }
  };

  //   ---------------------------onBoarded import customer------------------
  const handleOnBoardFormChange = (name, value) => {
    const newOnBoardData = { ...onBoardData };
    if (name === "phoneNo" || name === "emailOtp" || name === "phoneOtp") {
      if (isNaN(value)) {
        return;
      }
    }
    newOnBoardData[name] = value;
    setOnBoardData(newOnBoardData);
  };

  const otpOnBoardValidation = ({ name, value }) => {
    let newOnBoardDataError = { ...onBoardDataError };
    const otpOnBoardValidation = validationHelper.otp(value);
    newOnBoardDataError[name] = otpOnBoardValidation.message;
    setOnBoardDataError(newOnBoardDataError);
    return otpOnBoardValidation.isValid;
  };

  const handleOnBoardEmailValidation = () => {
    let newError = { ...onBoardDataError };
    const emailValidation = validationHelper.email(onBoardData?.email);
    newError.email = emailValidation.message;
    setOnBoardDataError(newError);
    return emailValidation.isValid;
  };

  const handleOnBoardPhoneValidation = () => {
    let newError = { ...onBoardDataError };
    const mobileValidation = validationHelper.mobile(onBoardData?.mobile);
    newError.phoneNo = mobileValidation.message;
    setOnBoardDataError(newError);
    return mobileValidation.isValid;
  };

  const handleOnBoardEmailOtpSend = async () => {
    if (!handleOnBoardEmailValidation()) {
      return;
    }
    const payload = {
      name: onBoardData?.name,
      type: "email",
      email: onBoardData?.email,
      mobile: "",
      user_type: "customer",
      user_id: "",
    };
    try {
      const res = await sendOTPAction(payload).unwrap();
      console.log("res : ", res.result);
      if (res.status === 1) {
        setIsEmailOtpSend(true);
        startEmailTimer(5);
      }
    } catch (err) {
      console.log("err:", err);
    }
  };

  const handleOnBoardEmailOtpSubmit = async () => {
    if (
      !otpOnBoardValidation({ name: "emailOtp", value: onBoardData.emailOtp })
    ) {
      return;
    }
    const payload = {
      name: onBoardData?.name,
      type: "email",
      email: onBoardData?.email,
      mobile: "",
      otp: onBoardData?.emailOtp,
      user_id: parseInt(onBoardData?.user_id),
    };
    try {
      let res = await confirmEmailOtpAction(payload);
      res = res.data;
      if (res.status === 1) {
        dispatch(
          addCustomerActions.setCustomerUserId({ userId: res.result.user_id })
        );
        setUserId(res.result.user_id);
        setIsEmailOnboardVerified(true);
        setIsEmailVerified(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleOnBoardPhoneOtpSubmit = async () => {
    if (
      !otpOnBoardValidation({ name: "phoneOtp", value: onBoardData?.phoneOtp })
    ) {
      return;
    }
    const payload = {
      type: "mobile",
      email: "",
      mobile: onBoardData?.mobile,
      otp: onBoardData?.phoneOtp,
      user_id: parseInt(onBoardData?.user_id),
    };
    try {
      const res = await confirmPhoneOtpAction(payload);
      if (res.data.status === 1) {
        handleStepChange(
          Number(onBoardData?.current_stage + 2),
          payload.user_id
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleOnBoardPhoneOtpSend = async () => {
    if (!handleOnBoardPhoneValidation()) {
      return;
    }
    const payload = {
      name: onBoardData?.name,
      type: "mobile",
      email: onBoardData?.email,
      mobile: onBoardData?.mobile,
      user_type: "customer",
      user_id: "",
    };
    try {
      const res = await sendOTPAction(payload).unwrap();
      if (res.status === 1) {
        setIsPhoneOtpSend(true);
        startPhoneTimer();
      }
    } catch (err) {
      console.log("err:", err);
    }
  };

  // console.log("riskDetailsAnswer ==> ", {
  //   isPrevAnsApiLoading,
  //   riskDetailsAnswer,
  // });

  if (isPrevAnsApiLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {isBasicDetailsCompleted ? (
        <CustomerRiskDetails
          onBoardData={onBoardData}
          handleAnswers={handleRiskDetailAnswers}
          handlePreviousStep={handleRiskDetailPreviousStep}
          JumpToPrevQuestion={JumpToPrevQuestion}
          riskAssesmentQts={riskAssesmentQts}
          currentQtsId={currentQtsId}
          totalQts={totalQts}
          riskPoints={riskPoints}
          riskDetailsAnswer={riskDetailsAnswer}
        />
      ) : (
        <CustomerDetails
          onBoardData={onBoardData}
          initialOnBoardData={initialOnBoardData}
          onBoardDataError={onBoardDataError}
          handleOnBoardFormChange={handleOnBoardFormChange}
          otpOnBoardValidation={otpOnBoardValidation}
          handleOnBoardEmailValidation={handleOnBoardEmailValidation}
          handleOnBoardEmailOtpSend={handleOnBoardEmailOtpSend}
          handleOnBoardEmailOtpSubmit={handleOnBoardEmailOtpSubmit}
          handleOnBoardPhoneOtpSend={handleOnBoardPhoneOtpSend}
          handleOnBoardPhoneOtpSubmit={handleOnBoardPhoneOtpSubmit}
          handleBasicFormChange={handleBasicFormChange}
          handleEmailOtpSubmit={handleEmailOtpSubmit}
          handlePhoneOtpSubmit={handlePhoneOtpSubmit}
          handleEmailOtpSend={handleEmailOtpSend}
          handlePhoneOtpSend={handlePhoneOtpSend}
          formData={basicDetails}
          isEmailOtpSend={isEmailOtpSend}
          isPhoneOtpSend={isPhoneOtpSend}
          isEmailVerified={isEmailVerified}
          isEmailOnboardVerified={isEmailOnboardVerified}
          basicDetailsError={basicDetailsError}
          emailSec={emailSec}
          emailOtpActive={emailOtpActive}
          phoneSec={phoneSec}
          phoneOtpActive={phoneOtpActive}
        />
      )}
    </>
  );
};

export default CustomerDetailsContainer;
