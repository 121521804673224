// Import necessary functions and modules
import { createApi } from "@reduxjs/toolkit/query/react";
import { apiSliceInterceptor } from "stores/redux/apiSliceInterceptor";

import { tradeParser } from "stores/redux/apiParser/tradeParser";
import { dividendParser } from "stores/redux/apiParser/dividendParser";

// Create an API client named "trades" using createApi function
const trades = createApi({
  reducerPath: "trades",
  baseQuery: apiSliceInterceptor.baseQueryWithInterceptor,
  tagTypes: ["GET_TRADES", "DOWNLOAD"],

  endpoints: (qb) => ({
    //querys
    getHighCustomersApi: qb.query({
      query: () => `api/trade/customers?risk_profile_id=1`,
    }),
    getModerateCustomersApi: qb.query({
      query: () => `api/trade/customers?risk_profile_id=2`,
    }),
    getLowCustomersApi: qb.query({
      query: () => `api/trade/customers?risk_profile_id=3`,
    }),
    getAllCustomersApi: qb.query({
      query: ({
        product_id,
        exchange,
        symbol,
        ordertype,
        noGlobalLoading = false,
      }) => ({
        url: `api/trade/customers`,
        params: {
          product_id: product_id,
          exchange: exchange,
          symbol: symbol,
          ordertype: ordertype,
          noGlobalLoading,
        },
      }),
      transformResponse: (response) => response.result,
    }),
    // api/trade/customers?exchange=NSE&symbol=AMRUTANJAN&ordertype=SELL
    getStackListApi: qb.query({
      query: ({ productId, noGlobalLoading = false }) => ({
        url: `api/trade/send-call-stack-list/${productId}`,
        params: { noGlobalLoading },
      }),
    }),
    getTradeUsingId: qb.query({
      query: ({ productId, StackTradeId }) =>
        `/api/trade/get-latest-trade/${productId}/${StackTradeId}`,
    }),
    getTradeUsingIds: qb.query({
      query: ({ PRODUCT_ID, StackTradeId }) =>
        `/api/trade/get-latest-trade/${PRODUCT_ID}/${StackTradeId}`,
    }),
    getTradeDetailsUsingId: qb.query({
      query: ({ productId, StackTradeId }) =>
        `/api/trade/transaction-get-latest-trade/${productId}/${StackTradeId}`,
    }),

    // Get stock list for Custom
    getStockListApi: qb.query({
      query: ({ search = "", exchange, noGlobalLoading }) => ({
        url: `api/company`,
        params: { search, exchange, noGlobalLoading },
      }),
      transformResponse: dividendParser.allStocks,
    }),

    // Get stock list for Algo
    getStockListFnoApi: qb.query({
      query: ({ search = "", exchange, noGlobalLoading }) => ({
        url: `api/fno`,
        params: { search, exchange, noGlobalLoading },
      }),
      transformResponse: dividendParser.allStocks,
    }),

    // getStockListApi: qb.query({
    //   query: (payload) => {
    //     return `api/company?search=${payload.searchSymbol}&exchange=${payload.exchange}`;
    //   },
    //   // transformResponse: (response) => {
    //   //   const data = response;
    //   //   console.log(data, "datstockdataaa"); // Logging the data
    //   //   return data; // Returning the data
    //   // },
    // }),

    getTradeUpdateData: qb.mutation({
      query: ({ trades = [] }) => ({
        url: `api/trade/get-multiple-trade`,
        method: "POST",
        body: { trades: trades }, // Adjusted body format
        headers: {
          "Content-Type": "application/json",
        },
        invalidatesTags: ["GET_TRADES_UPDATE_DATA"],
      }),
      transformResponse: (response) => response.result,
    }),
    getTradeCallUpdate: qb.mutation({
      query: ({
        transaction_id,
        exchange,
        stock_name,
        quantity,
        price,
        productId,
        order_type,
      }) => ({
        url: `api/trade/update-trade`,
        params: {
          productId: productId,
        },
        method: "POST",
        body: {
          transaction_id,
          exchange,
          stock_name,
          quantity,
          price,
          order_type,
        }, // Adjusted body format
        headers: {
          "Content-Type": "application/json",
        },
        invalidatesTags: ["GET_TRADES_UPDATE_DATA"],
      }),
      transformResponse: (response) => response.result,
    }),

    getTradesApi: qb.mutation({
      query: ({
        product_id,
        page = 0,
        pageSize = 5,
        payload = {},
        isSquareOff,
      }) => ({
        url: `api/trade/get-trades-list/${product_id}`,
        params: {
          page: page + 1,
          take: pageSize,
          is_square_off: isSquareOff, // Add the is_square_off parameter
        },
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
        },
        invalidatesTags: ["GET_TRADES"],
      }),

      transformResponse: (response) => {
        const data = tradeParser.tradesListing(response.result);
        return {
          data: data,
          totalRecord: response.total_record,
        };
      },
    }),

    // Mutation  to add a new trade item
    addTradesApi: qb.mutation({
      query: (payload) => ({
        url: `api/trade/add-trade`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),

    squareOffTradesApi: qb.mutation({
      query: (payload) => ({
        url: `api/trade/square-off-send-calls`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),

    editTradesApi: qb.mutation({
      query: ({payload, editedTradeId}) => {
        console.log("editedTradeId",editedTradeId);

        return {
          url: `api/trade/send-call-stack-list/update-pending-trade?trade_id=${editedTradeId}`,
          method: "PUT",
          body: payload,
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
    }),

    updatePendingApi: qb.mutation({
      query: ({payload}) => {
        return {
          url: `api/customer/portal/update-pending-trade`,
          method: "PUT",
          body: payload,
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
    }),
    // Send call for single trade
    sendTradeCallsApi: qb.mutation({
      query: (payload) => ({
        url: `api/trade/send-calls`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),

    // Send call for import trade
    sendMultipleTradeCallsApi: qb.mutation({
      query: (payload) => ({
        url: `api/trade/send-multiple-calls`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),

    executePendingTrade: qb.mutation({
      query: (payload) => ({
        url: `api/trade/execute-pending-trade`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
    updateTradeApi: qb.mutation({
      query: (payload) => ({
        url: `api/trade/update-trade`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),

    // donwload Trades list
    getDownloadTradeList: qb.mutation({
      query: ({ product_id, payload = {} }) => ({
        url: `api/trade/download-trades-list/${product_id}`,
        method: "POST", // Assuming you want to send the payload in the body of a POST request
        body: payload, // Pass the entire payload object as the request body
      }),
      transformResponse: (response) => response.result,
    }),

    // Import Trade: Sample File donwload
    downloadSampleImportFileApi: qb.mutation({
      query: ({ payload = {} }) => ({
        url: `api/trade/download-trade`,
        method: "POST", // Assuming you want to send the payload in the body of a POST request
        body: payload, // Pass the entire payload object as the request body
      }),
      transformResponse: (response) => response,
    }),

    // Import Trade: File upload
    importTradeSendFileApi: qb.mutation({
      query: (formData) => ({
        url: `api/trade/import-trade`,
        method: "POST",
        body: formData,
      }),
      transformResponse: (response) =>
        tradeParser.importTradeFile(response.result),
    }),

    // Import Trade: Validate Trades file data
    importProceedSelectRisk: qb.mutation({
      query: (data) => ({
        url: `api/trade/validate-trade`,
        method: "POST",
        body: {
          result: data.map((item) => ({
            exchange: item?.exchange,
            symbol: item?.symbol,
            action: item?.action,
            is_percent: item?.is_percent,
            quantity: parseInt(item?.quantity),
            lot_size: parseInt(item?.lot_size),
            percent: item?.percent,
            order_type: item?.order_type,
            price: parseInt(item?.price),
            is_stop_loss: item?.is_stop_loss,
            stop_loss: item?.stop_loss,
            target: parseInt(item?.target),
            expiry_date: item?.expiry_date,
            expiry_time: item?.expiry_time,
            is_active: item?.is_active,
            // product_id: parseInt(item?.product_id),
          })),
        },
      }),
      transformResponse: (response) =>
        tradeParser.importTradeFile(response.result),
    }),

    // Import Trade: Final submit after validating
    proceedToImportTrade: qb.mutation({
      query: ({ trades, riskProfile = [], productId }) => ({
        url: `api/trade/proceed-to-import-trade`,
        method: "POST",
        body: {
          trades: trades.map((item) => ({
            exchange: item?.exchange,
            symbol: item?.symbol,
            action: item?.action,
            is_percent: item?.is_percent,
            quantity: parseInt(item?.quantity ? item?.quantity : 0),
            lot_size: parseInt(item?.lot_size ? item?.lot_size : 0),
            percent: item?.percent ? item?.percent : 0,
            order_type: item?.order_type,
            price: parseInt(item?.price ? item?.price : 0),
            is_stop_loss: item?.is_stop_loss,
            stop_loss: item?.stop_loss ? item?.stop_loss : 0,
            target: parseInt(item?.target ? item?.target : 0),
            expiry_date: item?.expiry_date,
            expiry_time: item?.expiry_time,
            is_active: item?.is_active,
            product_id: parseInt(productId),
          })),
          risk_profile: riskProfile.map((p) => ({ risk_profile_id: p })),
          product_id: productId,
        },
      }),
      transformResponse: (response) =>
        tradeParser.ProceedToImportTradeRes(response.result),
    }),

    // Download Excel file on listing table.
    downloadExcelData: qb.mutation({
      query: ({ product_id, payload = {} }) => ({
        url: `api/trade/download-trades-list/${product_id}`,
        method: "POST",
        body: payload,
      }),
      transformResponse: (response) => response.result,
    }),
  }),
});

// Export the reducer generated by the createApi function
export const tradesApiReducer = trades.reducer;

// Export an object containing middleware, reducer path, and a function for making API requests
export const tradesApiAction = {
  middleware: trades.middleware,
  reducerPath: trades.reducerPath,
  getHighCustomersApi: trades.useLazyGetHighCustomersApiQuery,
  getModerateCustomersApi: trades.useLazyGetModerateCustomersApiQuery,
  getLowCustomersApi: trades.useLazyGetLowCustomersApiQuery,
  getAllCustomersApi: trades.useLazyGetAllCustomersApiQuery,
  getStackListApi: trades.useLazyGetStackListApiQuery,
  getTradeUsingId: trades.useLazyGetTradeUsingIdQuery,
  getTradeUsingIds: trades.useLazyGetTradeUsingIdsQuery,
  getTradeDetailsUsingId: trades.useLazyGetTradeDetailsUsingIdQuery,
  getStockListApi: trades.useLazyGetStockListApiQuery,
  getStockListFnoApi: trades.useLazyGetStockListFnoApiQuery,
  getTradeUpdateData: trades.useGetTradeUpdateDataMutation,
  getTradesApi: trades.useGetTradesApiMutation,
  executePendingTrade: trades.useExecutePendingTradeMutation,
  getTradeCallUpdate: trades.useGetTradeCallUpdateMutation,
  addTradesApi: trades.useAddTradesApiMutation,
  squareOffTradesApi: trades.useSquareOffTradesApiMutation,
  editTradesApi: trades.useEditTradesApiMutation,
  updatePendingApi: trades.useUpdatePendingApiMutation,
  sendTradeCallsApi: trades.useSendTradeCallsApiMutation,
  sendMultipleTradeCallsApi: trades.useSendMultipleTradeCallsApiMutation,
  updateTradeApi: trades.useUpdateTradeApiMutation,
  getDownloadTradeList: trades.useGetDownloadTradeListMutation,
  importTradeSendFileApi: trades.useImportTradeSendFileApiMutation,
  importProceedSelectRisk: trades.useImportProceedSelectRiskMutation,
  proceedToImportTrade: trades.useProceedToImportTradeMutation,
  downloadSampleImportFileApi: trades.useDownloadSampleImportFileApiMutation,

  downloadExcelData: trades.useDownloadExcelDataMutation,
};
