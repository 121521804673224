// Import necessary functions and modules
import { createApi } from "@reduxjs/toolkit/query/react";
import { customerReportsParser } from "stores/redux/apiParser/customerReportsParser";
import { ReportsMarketParser } from "stores/redux/apiParser/marketParser";
import { ReportsHoldingParser } from "stores/redux/apiParser/reportHoldingParser";
import { ReportsParser } from "stores/redux/apiParser/reportParser";

import { apiSliceInterceptor } from "stores/redux/apiSliceInterceptor";

const reports = createApi({
  reducerPath: "reports",
  baseQuery: apiSliceInterceptor.baseQueryWithInterceptor,
  tagTypes: ["GET_REPORTS"],

  endpoints: (qb) => ({

  getCustomerReportsApi: qb.mutation({
    query: ({ product_id, page = 0, pageSize = 5, payload = {} }) => ({
      url: `api/customer/reports/get-trades-list`,
      params: { page: page + 1, take: pageSize },
      method: "POST",
      body: payload,
      headers: {
        "Content-Type": "application/json",
      },
      invalidatesTags: ["GET_REPORTS"],
    }),
    transformResponse: (response) => {
      const data = customerReportsParser.customerReportsListing(response.result);
      
     return {
        data: data,
        totalRecord: response.total_record,
        cardData: response.card_data,
        cardsPerRow: response.cards_per_row
      };
    },

  }),

  downloadExcelData: qb.mutation({
    query: ({ payload = {}}) => ({
      url: `api/customer/reports/export-all-record`,
      method: "POST",
      body: payload
    }),
    transformResponse: (response) => response.result,
  }),

  
  downloadSingleData: qb.mutation({
    query: (transactionId) => ({
      url: `api/customer/reports/download-single-record/${transactionId}`,
      method: "GET",
    }),
    transformResponse: (response) => response.result,
  }),

  getReportsApi: qb.mutation({
    query: ({ product_id, page = 0, pageSize = 5, payload = {} }) => ({
      url: `api/ria/customer/reports/get-trades-list`,
      params: { page: page + 1, take: pageSize },
      method: "POST",
      body: payload,
      headers: {
        "Content-Type": "application/json",
      },
      invalidatesTags: ["GET_RIA_REPORTS"],
    }),
    transformResponse: (response) => {
      const data = ReportsParser.ReportsListing(response.result);
      
     return {
        data: data,
        totalRecord: response.total_record,
        cardData: response.card_data,
        cardsPerRow: response.cards_per_row
      };
    },

  }),

  downloadTradeReportsExcelData: qb.mutation({
    query: ({ payload = {}}) => ({
      url: `api/ria/customer/reports/export-all-record`,
      method: "POST",
      body: payload
    }),
    transformResponse: (response) => response.result,
  }),

  downloadTradeReportsSingleData: qb.mutation({
    query: (transactionId) => ({
      url: `api/ria/customer/reports/download-single-record/${transactionId}`,
      method: "GET",
    }),
    transformResponse: (response) => response.result,
  }),


  getMarketReportsApi: qb.mutation({
    query: ({ product_id, page = 0, pageSize = 5, payload = {} }) => ({
      url: `api/ria/customer/reports/martket-unrealized`,
      params: { page: page + 1, take: pageSize },
      method: "POST",
      body: payload,
      headers: {
        "Content-Type": "application/json",
      },
      invalidatesTags: ["GET_MARKET_RIA_REPORTS"],
    }),
    transformResponse: (response) => {
      const data = ReportsMarketParser.ReportsMarketListing(response.result);
      
     return {
        data: data,
        totalRecord: response.total_record,
        cardData: response.card_data,
        cardsPerRow: response.cards_per_row
      };
    },

  }),

  downloadMarketReportsExcelData: qb.mutation({
    query: ({ payload = {}}) => ({
      url: `api/ria/customer/reports/export-all-martket-unrealized`,
      method: "POST",
      body: payload
    }),
    transformResponse: (response) => response.result,
  }),
 
  downloadMarketReportsSingleData: qb.mutation({
    query: (portfolioId) => ({
      url: `api/ria/customer/reports/export-single-martket-unrealized?portfolio_id=${portfolioId}`,
      method: "GET",
    }),
    transformResponse: (response) => response.result,
  }),


  
  getHoldingReportsApi: qb.mutation({
    query: ({ product_id, page = 0, pageSize = 5, payload = {} }) => ({
      url: `api/ria/customer/reports/holding`,
      params: { page: page + 1, take: pageSize },
      method: "POST",
      body: payload,
      headers: {
        "Content-Type": "application/json",
      },
      invalidatesTags: ["GET_HOLDING_RIA_REPORTS"],
    }),
    transformResponse: (response) => {
      const data = ReportsHoldingParser.ReportsHoldingListing(response.result);
      
     return {
        data: data,
        totalRecord: response.total_record,
        cardData: response.card_data,
        cardsPerRow: response.cards_per_row
      };
    },

  }),

  downloadHoldingReportsExcelData: qb.mutation({
    query: ({ payload = {}}) => ({
      url: `api/ria/customer/reports/export-all-holding`,
      method: "POST",
      body: payload
    }),
    transformResponse: (response) => response.result,
  }),
  
  // downloadHoldingReportsSingleData: qb.mutation({
  //   query: (transactionId) => ({
  //     url: `api/ria/customer/reports/download-single-record/${transactionId}`,
  //     method: "GET",
  //   }),
  //   transformResponse: (response) => response.result,
  // }),



}),
});

export const ReportsApiReducer = reports.reducer;

export const ReportsApiAction = {
  middleware: reports.middleware,
  reducerPath: reports.reducerPath,
  getCustomerReportsApi: reports.useGetCustomerReportsApiMutation,
  downloadExcelData: reports.useDownloadExcelDataMutation,
  downloadSingleData:reports.useDownloadSingleDataMutation,

  getReportsApi: reports.useGetReportsApiMutation,
  downloadTradeReportsExcelData: reports.useDownloadTradeReportsExcelDataMutation,
  downloadTradeReportsSingleData:reports.useDownloadTradeReportsSingleDataMutation,

  getMarketReportsApi: reports.useGetMarketReportsApiMutation,
  downloadMarketReportsExcelData: reports.useDownloadMarketReportsExcelDataMutation,
  downloadMarketReportsSingleData:reports.useDownloadMarketReportsSingleDataMutation,

  getHoldingReportsApi: reports.useGetHoldingReportsApiMutation,
  downloadHoldingReportsExcelData: reports.useDownloadHoldingReportsExcelDataMutation,
  // downloadHoldingReportsSingleData:reports.useDownloadHoldingReportsSingleDataMutation,



};
