import React from "react";
import Paper from "components/common/Paper";
import Stack from "components/common/Stack";
import Grid from "components/common/Grid";
import Text from "components/common/Text";
import Box from "components/common/Box";

const ReportsTradeCard = ({ data, stacksLoading = false, cardsPerRow = 4 }) => {
  let PerRow;
  if (cardsPerRow <= 2) {
    PerRow = 6;
  } else if (cardsPerRow === 3) {
    PerRow = 4;
  } else if (cardsPerRow === 4) {
    PerRow = 3;
  } else if (cardsPerRow >= 5) {
    PerRow = 2.4;
  }

  // data.sort(() => )

  return (
    <Stack mb={3} mt={3}>
      <Grid container spacing={2}>
        {data.map((element, index) => {
          const { display_heading, sequence = "", data_1 = null } = element;
          console.log(" element==>", element);
          let subHeadingFontSize_1 = data_1?.sub_heading ? "18px" : "27px";

          let d1v_type = data_1?.value_type || null;
          let d1sv_type = data_1?.suffix_val_type || null;

          // gain/loss color
          let profitVal = data_1.suffix_val;
          let suffixValColor = "#000000de";
          if (d1sv_type && d1sv_type === "PERCENT") {
            if (profitVal == 0) {
              suffixValColor = "#676c76";
            } else if (profitVal < 0) {
              suffixValColor = "#ff4949";
            } else if (profitVal > 0) {
              suffixValColor = "#219653";
            }
          }

          return (
            <Grid item xs={PerRow} key={`cards_index_${element.id}`}>
              {/* Card */}
              <Paper sx={{ py: 3, px: 2 }}>
                {/* main heading */}
                <Box sx={{ mb: 2, fontWeight: 600 }} component="h4">
                  {display_heading}
                </Box>
                <Stack
                  sx={{
                    justifyContent: "space-between",
                    flexFlow: "row nowrap",
                    height: "50px",
                  }}
                >
                  {/* data 1 */}
                  <Stack>
                    {/* sub_heading */}
                    {data_1.sub_heading ? (
                      <Box component="h6" sx={{ color: "gray", mb: 0.5 }}>
                        {data_1.sub_heading}
                      </Box>
                    ) : null}
                    {/* value */}
                    <Text
                      sx={{ fontSize: subHeadingFontSize_1, fontWeight: 600 }}
                    >
                      {d1v_type && d1v_type === "CURRENCY" ? "₹ " : null}
                      {data_1.value}
                      {d1v_type && d1v_type === "PERCENT" ? "%" : null}

                      {/* suffix */}
                      {data_1?.suffix_val || data_1.suffix_val == 0 ? (
                        <Text
                          component="span"
                          sx={{
                            fontSize: "14px",
                            ml: "5px",
                            color: suffixValColor,
                          }}
                        >
                          {d1sv_type && d1sv_type === "CURRENCY" ? "₹ " : null}
                          {data_1.suffix_val}
                          {d1sv_type && d1sv_type === "PERCENT" ? "%" : null}
                        </Text>
                      ) : null}
                    </Text>
                  </Stack>
                </Stack>
              </Paper>
            </Grid>
          );
        })}
      </Grid>
    </Stack>
  );
};

export default ReportsTradeCard;
