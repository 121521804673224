import React, { useState } from "react";
import Box from "components/common/Box";
import Text from "components/common/Text";
import DownloadButton from "components/common/DownloadButton";
import { styled } from "@mui/material";
import { adminDashBoardApiAction } from "stores/redux/apiSlices/admin/adminDashBoardApiSlice";
import dayjs from "dayjs";

// Styled components for consistent styling
const KeyValueRow = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: theme.spacing(1),
  width: "100%", // Ensure the row takes full width
  flexWrap: "wrap", // Allows wrapping of content
}));

const KeyContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flex: "1 1 0", // Allow it to grow and shrink as needed
  minWidth: 0, // Prevent overflow issues
}));

const KeyLabel = styled(Text)(({ theme }) => ({
  fontSize: "16px",
  fontWeight: 500,
  color: "#101828",
  textAlign: "left",
  flex: "1",
  whiteSpace: "nowrap", // Ensure the label doesn't wrap
}));

const Colon = styled(Text)(({ theme }) => ({
  fontSize: "16px",
  fontWeight: 400,
  color: "#101828",
  margin: "0 8px", // Spacing around colon
  whiteSpace: "nowrap", // Ensure colon doesn't wrap
}));

const KeyValue = styled(Text)(({ theme }) => ({
  fontSize: "16px",
  fontWeight: 400,
  color: "#667085",
  flex: "2", // Allow value to take up more space if needed
  wordWrap: "break-word", // Break long words to avoid overflow
  whiteSpace: "pre-wrap", // Preserve whitespace and wrap text as needed
}));

const RiaText = styled(Text)(({ theme }) => ({
  fontSize: "16px",
  fontWeight: 400,
  color: "#667085",
}));

const TableDrawer = (props) => {
  const { singleRiaData } = props;
  const [fileBase64, setFileBase64] = useState(null);

  const [fetchProfileDoc] = adminDashBoardApiAction.getLazyProfileDocApi();

  const fileDownload = async (fileUrl, filename) => {
    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const reader = new FileReader();
      reader.onload = () => {
        setFileBase64(reader.result);
        downloadFile(filename, reader.result);
      };
      reader.readAsDataURL(blob);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const downloadFile = (filename, base64String) => {
    const link = document.createElement("a");
    link.href = base64String;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (
    !singleRiaData ||
    !singleRiaData.data ||
    !singleRiaData.data.message ||
    !singleRiaData.data.message.result
  ) {
    return <div>Loading...</div>; // Render a loading indicator while data is being fetched
  }

  const {
    name,
    email,
    mobile,
    basl_no,
    sebi_reg_no,
    basl_expiry,
    aadhar_card,
    pan_card,
    nism_number,
    role_name,
    employeeDetails,
  } = singleRiaData.data.message.result;

  const formattedBaslExpiry = dayjs(basl_expiry).format("DD-MM-YYYY");
  const { documents } = singleRiaData.data.message.result;

  return (
    <>
      {role_name === "RIA" && (
        <>
          <KeyValueRow>
            <KeyContainer>
              <KeyLabel>Customer name</KeyLabel>
              <Colon>:</Colon>
            </KeyContainer>
            <KeyValue>{name}</KeyValue>
          </KeyValueRow>
          <KeyValueRow>
            <KeyContainer>
              <KeyLabel>Email address</KeyLabel>
              <Colon>:</Colon>
            </KeyContainer>
            <KeyValue>{email}</KeyValue>
          </KeyValueRow>
          <KeyValueRow>
            <KeyContainer>
              <KeyLabel>Phone number</KeyLabel>
              <Colon>:</Colon>
            </KeyContainer>
            <KeyValue>{mobile}</KeyValue>
          </KeyValueRow>
          <KeyValueRow>
            <KeyContainer>
              <KeyLabel>SEBI registration</KeyLabel>
              <Colon>:</Colon>
            </KeyContainer>
            <KeyValue>{sebi_reg_no}</KeyValue>
          </KeyValueRow>
          <KeyValueRow>
            <KeyContainer>
              <KeyLabel>BASL Number</KeyLabel>
              <Colon>:</Colon>
            </KeyContainer>
            <KeyValue>{basl_no}</KeyValue>
          </KeyValueRow>
          <KeyValueRow>
            <KeyContainer>
              <KeyLabel>Expiry date</KeyLabel>
              <Colon>:</Colon>
            </KeyContainer>
            <KeyValue>{formattedBaslExpiry}</KeyValue>
          </KeyValueRow>
        </>
      )}

      {role_name === "SubBroker" && (
        <>
          <KeyValueRow>
            <KeyContainer>
              <KeyLabel>Subbroker name</KeyLabel>
              <Colon>:</Colon>
            </KeyContainer>
            <KeyValue>{name}</KeyValue>
          </KeyValueRow>
          <KeyValueRow>
            <KeyContainer>
              <KeyLabel>Email address</KeyLabel>
              <Colon>:</Colon>
            </KeyContainer>
            <KeyValue>{email}</KeyValue>
          </KeyValueRow>
          <KeyValueRow>
            <KeyContainer>
              <KeyLabel>Phone number</KeyLabel>
              <Colon>:</Colon>
            </KeyContainer>
            <KeyValue>{mobile}</KeyValue>
          </KeyValueRow>
          <KeyValueRow>
            <KeyContainer>
              <KeyLabel>Aadhar number</KeyLabel>
              <Colon>:</Colon>
            </KeyContainer>
            <KeyValue>{aadhar_card}</KeyValue>
          </KeyValueRow>
          <KeyValueRow>
            <KeyContainer>
              <KeyLabel>Pan Number</KeyLabel>
              <Colon>:</Colon>
            </KeyContainer>
            <KeyValue>{pan_card}</KeyValue>
          </KeyValueRow>
          <KeyValueRow>
            <KeyContainer>
              <KeyLabel>Nism Number</KeyLabel>
              <Colon>:</Colon>
            </KeyContainer>
            <KeyValue>{nism_number}</KeyValue>
          </KeyValueRow>

          {/* Conditionally render employee fields only if data is available */}
          {employeeDetails?.nism_number && (
            <KeyValueRow>
              <KeyContainer>
                <KeyLabel>Emp Nism No</KeyLabel>
                <Colon>:</Colon>
              </KeyContainer>
              <KeyValue>{employeeDetails.nism_number}</KeyValue>
            </KeyValueRow>
          )}
          {employeeDetails?.pan_card && (
            <KeyValueRow>
              <KeyContainer>
                <KeyLabel>Emp Pan No</KeyLabel>
                <Colon>:</Colon>
              </KeyContainer>
              <KeyValue>{employeeDetails.pan_card}</KeyValue>
            </KeyValueRow>
          )}
          {employeeDetails?.aadhar_card && (
            <KeyValueRow>
              <KeyContainer>
                <KeyLabel>Emp Aadhar No</KeyLabel>
                <Colon>:</Colon>
              </KeyContainer>
              <KeyValue>{employeeDetails.aadhar_card}</KeyValue>
            </KeyValueRow>
          )}
        </>
      )}

      <Box>
        {documents.map((doc) => (
          <Box key={doc.document_id} sx={{ marginBottom: 2 }}>
            <RiaText>{doc.display_name}</RiaText>
            <DownloadButton
              children={doc.display_name}
              handleDownload={() => fileDownload(doc.file, doc.display_name)}
            />
          </Box>
        ))}
      </Box>
    </>
  );
};

export default TableDrawer;
