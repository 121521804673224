import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import rootReducers from "./redux/rootReducer";
import { todoApiAction } from "./redux/apiSlices/todoApiSlice";
import { authApiAction } from "./redux/apiSlices/authApiSlice";
import { plansApiAction } from "./redux/apiSlices/plans/plansApiSlice";
import { modelPortFolioApiAction } from "./redux/apiSlices/modelPortfolio/modelPortfolio";
import { familyApiAction } from "./redux/apiSlices/familyMembers/familyApiSlice";
import { addCustomerApiAction } from "./redux/apiSlices/addCustomerApiSlice";
import { commonApiAction } from "./redux/apiSlices/commonApiSlice";
import { dividendApiAction } from "./redux/dividend/dividendApiSlice";
import { customersApiAction } from "./redux/apiSlices/customers/customersApiSlice";
import { dashboardApiAction } from "./redux/apiSlices/Customer DashBoard/dashboardApiSlice";
import { profileApiAction } from "./redux/riaProfile/riaProfileSlice";
import { signUpApiAction } from "./redux/apiSlices/SignUp/signUpApiSlice";
import { CustomerDashboardApiAction } from "./redux/customerDashBoard/customerDashBoardApiSlice";
import { CustomerPortfolioApiAction } from "./redux/apiSlices/CustomerPortfolio/portfolioApiSlice";


import { tradesApiAction } from "./redux/apiSlices/trades/tradesApiSlice";
import { customerOtherDetailsApiAction } from "./redux/apiSlices/CustomerOtherDetailsApiSLice";
import { adminDashBoardApiAction } from "./redux/apiSlices/admin/adminDashBoardApiSlice";
import { adminProfileApiAction } from "./redux/apiSlices/admin/adminProfileApiSlice";
import { CustomerProfileApiAction } from "./redux/apiSlices/CustomerPortfolio/customerProfileApiSlice";
import { CustomerPortalOnboardApiAction } from "./redux/slices/customerPortalOnboardSlice";
import { ReportsApiAction } from "./redux/apiSlices/CustomerReports/reportsApiSlice";
const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([
      todoApiAction.middleware,
      authApiAction.middleware,
      plansApiAction.middleware,
      familyApiAction.middleware,
      customersApiAction.middleware,
      dividendApiAction.middleware,
      dashboardApiAction.middleware,
      profileApiAction.middleware,
      modelPortFolioApiAction.middleware,
      addCustomerApiAction.middleware,
      signUpApiAction.middleware,
			commonApiAction.middleware,
      CustomerDashboardApiAction.middleware,
      CustomerPortfolioApiAction.middleware,
      CustomerProfileApiAction.middleware,
      tradesApiAction.middleware,
      customerOtherDetailsApiAction.middleware,
      adminDashBoardApiAction.middleware,
      adminProfileApiAction.middleware,
      CustomerPortalOnboardApiAction.middleware,
      ReportsApiAction.middleware
    ]),
});

export const persistor = persistStore(store);