import { useState, useEffect } from "react";
import Box from "components/common/Box";
import Grid from "components/common/Grid";
import Text from "components/common/Text";
import Stack from "components/common/Stack";
import Button from "components/common/Button";
import Tab from "components/common/Tab";
import SideDrawer from "components/common/SideDrawer";
import _get from "lodash/get";
import DatePicker from "components/common/DatePicker";
import TextField from "components/common/TextField";
import DownloadButton from "components/common/DownloadButton";
import { clickAndDownloadFile } from "helpers/fileFormat";
import Breadcrumbs from "components/common/Breadcrumbs";
import CustomGrid from "components/datagrid/CustomGrid";
import { CustomerReportsdummyTableData } from "helpers/constants";
import DownloadIcon from "asset/icons/DownloadIcon";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FilterIcon from "asset/icons/FilterIcon";
import { Chip } from "@mui/material";
import { styled } from "@mui/styles";
import useDebounce from "hooks/Debounce";
import { ReportsApiAction } from "stores/redux/apiSlices/CustomerReports/reportsApiSlice";
import ReportsTradeCard from "components/Customer/ReportsTradeCard";
import Card from "components/common/Card";
import ImportIcon from "asset/ImportIcon";


const AddFilterBtn = styled(Button)(() => ({
  fontWeight: 500,
  borderRadius: "8px",
  borderColor: "#D0D5DD",
  p: 1,
}));

const ModelFilterDrawer = (props) => {
  const { handleChange, filterData } = props;

  return (
    <>
      <Stack spacing={2}>
        <Stack direction="row" alignItems="flex-end" spacing={2}>
          <Box sx={{ flex: 1 }}>
            <Text>Profit range (%)</Text>
            <TextField
              placeholder="Eg. 5%"
              name="profitFrom"
              onChange={(e) => {
                handleChange({
                  name: e.target.name,
                  value: e.target.value,
                });
              }}
              value={filterData.profitFrom}
            />
          </Box>
          <TextField
            sx={{ flex: 1 }}
            placeholder="Eg. 10%"
            name="profitTo"
            onChange={(e) => {
              handleChange({
                name: e.target.name,
                value: e.target.value,
              });
            }}
            value={filterData.profitTo}
          />
        </Stack>
        <Stack direction="row" alignItems="flex-end" spacing={2}>
          <Box sx={{ flex: 1 }}>
            <Text>Date range</Text>
            <DatePicker
              placeholder="Select Date"
              name="fromData"
              onChange={(value) => {
                handleChange({ name: "fromDate", value: value });
              }}
              value={filterData.fromDate}
            />
          </Box>
          <DatePicker
            sx={{ flex: 1 }}
            placeholder="Select Date"
            name="toData"
            onChange={(value) => {
              handleChange({ name: "toDate", value: value });
            }}
            value={filterData.toDate}
          />
        </Stack>
{/* 
        <Stack>
          <Text sx={{ color: "#242424", fontsize: "14px", fontWeight: 500 }}>
            Order type
          </Text>

          <Stack direction="row">
            <FormGroup>
              <Box sx={{ display: "flex", mb: "24px" }}>
                <FormControlLabel
                  control={<Checkbox size="small" />}
                  sx={{
                    mr: "32px",
                    color: "#242424",
                    fontSize: "16px",
                    fontWeight: 400,
                  }}
                  label="All"
                />
                <FormControlLabel
                  control={<Checkbox size="small" />}
                  sx={{
                    mr: "32px",
                    color: "#242424",
                    fontSize: "16px",
                    fontWeight: 400,
                  }}
                  label="LIMIT"
                />
                <FormControlLabel
                  control={<Checkbox size="small" />}
                  sx={{
                    mr: "32px",
                    color: "#242424",
                    fontSize: "16px",
                    fontWeight: 400,
                  }}
                  label="MARKET"
                />
              </Box>
            </FormGroup>
          </Stack>
        </Stack>

        <Stack>
          <Text sx={{ color: "#242424", fontsize: "14px", fontWeight: 500 }}>
            Action type
          </Text>

          <Stack direction="row">
            <FormGroup>
              <Box sx={{ display: "flex", mb: "24px" }}>
                <FormControlLabel
                  control={<Checkbox size="small" />}
                  sx={{
                    mr: "32px",
                    color: "#242424",
                    fontSize: "16px",
                    fontWeight: 400,
                  }}
                  label="All"
                />
                <FormControlLabel
                  control={<Checkbox size="small" />}
                  sx={{
                    mr: "32px",
                    color: "#242424",
                    fontSize: "16px",
                    fontWeight: 400,
                  }}
                  label="BUY"
                />
                <FormControlLabel
                  control={<Checkbox size="small" />}
                  sx={{
                    mr: "32px",
                    color: "#242424",
                    fontSize: "16px",
                    fontWeight: 400,
                  }}
                  label="SELL"
                />
              </Box>
            </FormGroup>
          </Stack>
        </Stack> */}
      </Stack>
    </>
  );
};

const ModelTableHeader = ({
  handleFilterDrawer,
  handleTradeDrawer,
  tradesTotalRecord,
  filterData,
  handleSearch,
  onDownloadExcel,

}) => {

  const totalTrades = `${tradesTotalRecord} Trades`;
  return (
    <>
      <Box sx={{ p: 2 }}>
        <Box sx={{ p: 1 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ mb: "0px" }}
          >
            <Box>
              <Text
                variant="body1"
                sx={{ fontWeight: 500, color: "#101828", mr: "15px" }}
              >
                Market to market unrealised report{" "}
                <Chip
                  sx={{ backgroundColor: "#E5EEFF", color: "#142E56" }}
                  label={totalTrades}
                />
              </Text>
              <Text variant="small" color="#667085" sx={{ mt: 0.5 }}>
                Here you can view and export reports accordingly.
              </Text>
            </Box>
            <Stack direction="row" alignItems="center" spacing={2}>
            <AddFilterBtn
                 sx={{ padding: "10px 16px" }}
                variant="outlined"
                startIcon={<ImportIcon />}
                onClick={() => onDownloadExcel()}
                // disabled={!tradesDetailsList.length}
              >
                Export
              </AddFilterBtn>
            <AddFilterBtn
              startIcon={<FilterIcon />}
              variant="outlined"
              onClick={handleFilterDrawer}
              sx={{ padding: "10px 16px" }}
            >
              Add Filters
            </AddFilterBtn>
            </Stack>
          
          </Stack>

          <TextField
              sx={{ maxWidth: "450px",mt:2 }}
              placeholder="Search"
              value={filterData.search}
              onChange={handleSearch}
            />
        </Box>
      </Box>
    </>
  );
};

function CustomerReportsContainer(props) {
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const [isTradeDrawerOpen, setIsTradeDrawerOpen] = useState(false);
  const [tradesPaginationModel, setTradesPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });
  const [tradeLoading, setTradeLoading] = useState(false);
  const [tradesDetails, setTradesDetails] = useState([]);
  const [cardDetails, setCardDetails] = useState([]);
  const [cardPerRowDetails, setCardPerRowDetails] = useState(0);
  const [tradesTotalRecord, setTradesTotalRecord] = useState(0);

  const [filterData, setFilterData] = useState({
    profitFrom: null,
    profitTo: null,
    fromDate: null,
    toDate: null,
    clearAll: 0,
    search: "",
    riskProfileName:"",


  });

  const [getMarketReportList] = ReportsApiAction.getMarketReportsApi();

  const [downloadMarketExcelData] = ReportsApiAction.downloadMarketReportsExcelData();

  const [singleDownload]= ReportsApiAction.downloadMarketReportsSingleData();
  
  // Filter, Search, get listing
  const debouncedValue = useDebounce(filterData?.search, 500);

  // LS: didUpdate
  useEffect(() => {
    getAndSetTradeListingData();
  }, [debouncedValue, filterData?.clearAll, tradesPaginationModel]);

  // Get Api details methods
  const getAndSetTradeListingData = async () => {
    let res = [];
    let payload = {};

    try {
      setTradeLoading(true);
      // add search
      payload.search = filterData?.search;

      payload.risk_profile_name=filterData?.riskProfileName;

      // add filter
      if (filterData.fromDate) {
        payload.date_range_from = filterData.fromDate;
      }
      if (filterData.toDate) {
        payload.date_range_to = filterData.toDate;
      }
      if (filterData.profitFrom) {
        payload.profit_range_from = filterData.profitFrom;
      }
      if (filterData.profitTo) {
        payload.profit_range_to = filterData.profitTo;
      }

      const { page, pageSize } = tradesPaginationModel;

      payload.noGlobalLoading = true; // restrics global loading.
      const res = await getMarketReportList({
        page,
        pageSize,
        payload: payload,
      });

      if (res?.data) {
        const { data, totalRecord, cardData, cardsPerRow } = res.data;
        
        setTradesDetails(data);
        setCardDetails(cardData);
        setCardPerRowDetails(cardsPerRow);
        setTradesTotalRecord(totalRecord);
        setIsFilterDrawerOpen(false);
      }
      setTradeLoading(false);
    } catch (e) {
      setTradeLoading(false);
    }
  };

  //Export Reports
  const onDownloadExcel = async () => {
    let payload = {};
   

    payload.search = filterData?.search;

    // add filter
    if (filterData.fromDate) {
      payload.date_range_from = filterData.fromDate;
    }
    if (filterData.toDate) {
      payload.date_range_to = filterData.toDate;
    }
    if (filterData.profitFrom) {
      payload.profit_range_from = filterData.profitFrom;
    }
    if (filterData.profitTo) {
      payload.profit_range_to = filterData.profitTo;
    }

    payload.noGlobalLoading = true; // restrics global loading.

    try {
      let file = await downloadMarketExcelData({
        // product_id: product_id,
        payload: payload,
      });
      const filename = [
        "Market-Report-List",
      ];
      if (file && file.data) {
        await clickAndDownloadFile({
          base64File: file.data,
          fileName: filename,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };



  // Filter params changes
  const handleFilterDataChange = async ({ name, value, clearAll = 0 }) => {
    if (clearAll) {
      let newFilterData = { ...filterData };
      newFilterData.fromDate = null;
      newFilterData.toDate = null;
      newFilterData.profitFrom = null;
      newFilterData.profitTo = null;
      newFilterData.clearAll = newFilterData.clearAll + 1;
      setFilterData(newFilterData);
      return;
    }

    if (["profitFrom", "profitTo"].includes(name) && isNaN(value)) {
      return;
    }

    let newFilterData = { ...filterData };
    if (["toDate", "fromDate"].includes(name) && value && value["$d"]) {
      newFilterData[name] = value["$d"];
    } else {
      newFilterData[name] = value;
    }

    setFilterData(newFilterData);
  };

  const handleFilterDrawer = () => {
    setIsFilterDrawerOpen(!isFilterDrawerOpen);
  };

  const handleTradeDrawer = () => {
    setIsTradeDrawerOpen(!isTradeDrawerOpen);
  };

  const dashboardCard = () => {
    let cardData = cardDetails || [];

    return cardData.map((card) => {
      let finalData = {
        display_heading: _get(card, "display_heading", "Card"),
        sequence: _get(card, "sequence", ""),
        data_1: {
          sub_heading: _get(card, "data_1.sub_heading", null),
          value: _get(card, "data_1.value", 0),
          value_type: _get(card, "data_1.value_type", ""),
          suffix_val: _get(card, "data_1.suffix_val", null),
          suffix_val_type: _get(card, "data_1.suffix_val_type", ""),
        },
      };

      return finalData;
    });
  };

  const handleDownloadClick = async (portfolioId) => {
    try {
      const file = await singleDownload(portfolioId);
      const filename = [
        "Single-Market-List",
      ];
      if (file && file.data) {
        await clickAndDownloadFile({
          base64File: file.data,
          fileName: filename,
        });
      }
      // Handle the response or trigger any download-related actions
    } catch (error) {
      console.error('Error downloading:', error);
      // Handle the error, maybe show a notification to the user
    }
  };
  
  
  
  const customerColumns = [
    {
      field: "stockName",
      headerName: "Stock name",
      flex: 1,
      renderCell: (params) => (
        <div style={{ color: "#242424", fontSize: "14px", fontWeight: 500 }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "totalQuantity",
      headerName: "Total quantity",
      flex: 1,
      renderCell: (params) => (
        <div style={{ color: "#676C76", fontSize: "14px", fontWeight: 400 }}>
          {params.value}
        </div>
      ),
    },
  
    {
      field: "amountInvested",
      headerName: "Amount invested",
      flex: 1,
      renderCell: (params) => (
        <div style={{ color: "#676C76", fontSize: "14px", fontWeight: 400 }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "unrealizedGainLoss",
      headerName: "Unrealized Gains/Loss",
      flex: 1,
      renderCell: (params) => (
        <div style={{ color: "#676C76", fontSize: "14px", fontWeight: 400 }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "marketValue",
      headerName: "Market value",
      flex: 1,
      renderCell: (params) => (
        <div style={{ color: "#676C76", fontSize: "14px", fontWeight: 400 }}>
          {params.value}
        </div>
      ),
    },
  
    {
      field: "portfolioId",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <Stack direction="row" alignItems="center">
          <DownloadIcon
            onClick={() => handleDownloadClick(params.value)}
            style={{ cursor: 'pointer' }}
          />
        </Stack>
      ),
    },
  ];


  return (
    <>
      <Box>
        <SideDrawer
          open={isFilterDrawerOpen}
          closeDrawer={handleFilterDrawer}
          title="Add Filters"
          subtitle="See the data in an organized manner by applying filters"
          handleSubmit={() => getAndSetTradeListingData()}
          cancelButtonText="Close"
          submitButtonText="submit"
          drawerWidth={500}
          primaryAction={() => handleFilterDataChange({ clearAll: 1 })}
        >
          <ModelFilterDrawer
            handleChange={handleFilterDataChange}
            filterData={filterData}
          />
        </SideDrawer>


        <Box
        sx={{
          maxWidth: "calc(100% + 48px)",
          margin: "0 -24px",
          marginBottom: "24px",
        }}
      >
        <Breadcrumbs link="Reports" Breadcrumb=" Market to market unrealised report" />
      </Box>

        <ReportsTradeCard
          data={dashboardCard()}
          cardsPerRow={cardPerRowDetails}
         />

        <Card >
          <ModelTableHeader
            handleFilterDrawer={handleFilterDrawer}
            handleTradeDrawer={handleTradeDrawer}
            tradesTotalRecord={tradesTotalRecord}
            handleSearch={(e) =>
              handleFilterDataChange({
                name: "search",
                value: e?.target?.value,
              })
            }
            filterData={filterData}
            onDownloadExcel={() => onDownloadExcel(filterData)}
          />

          <CustomGrid
            autoHeight
            list={tradesDetails}
            columnHeaderHeight={46}
            rowHeight={46}
            columns={customerColumns}
            count={tradesDetails.length}
            loading={tradeLoading}
            sx_wrapper={{
              borderRadius: "0px",
              "& .MuiDataGrid-columnHeaders": {
                borderRadius: "0px",
                backgroundColor: "red",
              },
            }}
            rowCount={tradesTotalRecord}
            pagination
            paginationMode="server"
            paginationModel={tradesPaginationModel}
            onPaginationModelChange={setTradesPaginationModel}
            // header={() =>
            //   ModelTableHeader({
            //     handleFilterDrawer,
            //     handleTradeDrawer,
            //     tradesTotalRecord,
            //   })
            // }
          />
        </Card>
      </Box>
    </>
  );
}

export default CustomerReportsContainer;
