import _get from "lodash/get";
import { uuid } from "helpers/utility";

const ReportsHoldingParser = {};

ReportsHoldingParser.ReportsHoldingListing = (data=[]) =>{
    let parsedData = data?.map((item) => {
        return {
          id: uuid(),
          stockName:_get(item, "stock_name", ""),
          customerName: _get(item, "customer_name", ""),
          clientCode:_get(item,"client_code",""),
          holdingDate:_get(item,"holdings_as_on_date",""),
          insNo:_get(item,"isin_no",""),
          scriptName:_get(item,"script_name",""),
          totalQuantity:_get(item,"total_quantity",""),
          purchaseDate:_get(item,"purchase_date",""),
          

          dateOfExecution: _get(item, "date_of_execution", ""),
          quantity: _get(item, "quantity", ""),
          orderType: _get(item, "order_type", ""),
          action: _get(item, "action", ""),
          buyPrice: _get(item, "buy_price", ""),
          sellPrice: _get(item, "sell_price", ""),
          drawdown: _get(item, "drawdown", ""),
          profit: _get(item, "profit", ""),
          transactionId:_get(item, "transaction_id", ""),
          taxPl: _get(item, "tax_pl", ""),
          riskProfile: _get(item, "risk_profile", ""),
          amountInvested:_get(item,"amount_invested",""),
        };
      });
      return parsedData;
}


export { ReportsHoldingParser };