import _get from "lodash/get";
import { uuid } from "helpers/utility";

const ReportsMarketParser = {};

ReportsMarketParser.ReportsMarketListing = (data=[]) =>{
    let parsedData = data?.map((item) => {
        return {
          id: uuid(),
          stockName:_get(item, "stock_name", ""),
          customerName: _get(item, "customer_name", ""),
          dateOfExecution: _get(item, "date_of_execution", ""),
          totalQuantity: _get(item, "total_qunatity", ""),
          orderType: _get(item, "order_type", ""),
          action: _get(item, "action", ""),
          buyPrice: _get(item, "buy_price", ""),
          sellPrice: _get(item, "sell_price", ""),
          drawdown: _get(item, "drawdown", ""),
          profit: _get(item, "profit", ""),
          portfolioId:_get(item, "portfolio_id", ""),
          taxPl: _get(item, "tax_pl", ""),
          riskProfile: _get(item, "risk_profile", ""),
          amountInvested:_get(item,"amount_invested",""),
          unrealizedGainLoss:_get(item,"unrealized_gain_loss",""),
          marketValue:_get(item,"market_value","")
        };
      });
      return parsedData;
}


export { ReportsMarketParser };