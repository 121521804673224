import React, { useState, useEffect } from "react";
import Grid from "components/common/Grid";
import Stack from "components/common/Stack";
import InvestmentTypeCard from "components/customerCard/CustomerInvestment";
import TradeCards from "components/customerCard/TradesCard";
import { useNavigate } from "react-router";
import Paper from "components/common/Paper";
import Text from "components/common/Text";
import _get from "lodash/get";
import Box from "components/common/Box";
import { styled } from "@mui/material";
import { customersApiAction } from "stores/redux/apiSlices/customers/customersApiSlice";
import { useSelector } from "react-redux";
import StackBars from "components/common/StackBars";
import { LineChart } from "@mui/x-charts/LineChart";
import { useLocation } from "react-router-dom";

const BoxStyled = styled(Box)(({ theme }) => ({
  height: "14px",
  width: "14px",
  borderRadius: "50%",
  backgroundColor: "#142E56",
}));

const PaperStyled = styled(Paper)(({ theme }) => ({
  alignItems: "center",
  padding: "24px",
  width: "auto",
  backgroundColor: "white",
  border: "1px solid white",
  height: "428px",
  borderRadius: "8px",
}));

const Performance = ({ isValue, setIsValue}) => {
  const [headerData, setHeaderData] = useState();

  const navigate = useNavigate();
  const location = useLocation();
  const select = useSelector((state) => state);

  const BlockUserID = location.state && location.state.BlockUserID;
	const BlockUserName = location.state && location.state.BlockUserName;
  const BlockUserStatus = location.state && location.state.BlockUserStatus;
  
	const user_id =  BlockUserID === null ? select?.toast?.breadCrumbData?.user_id : BlockUserID ;

  const [getPerformance, getPerformanceDetail = []] =
    customersApiAction.getPerformanceApi(user_id);
    console.log("getPerformanceDetail",getPerformanceDetail);
    

  const model_portfolio_graph =
    getPerformanceDetail?.data?.model_portfolio_graph;
  const minDate =
    getPerformanceDetail?.data?.model_portfolio_graph &&
    getPerformanceDetail.data.model_portfolio_graph.length > 0
      ? getPerformanceDetail.data.model_portfolio_graph[0]
      : "";
  const maxDate =
    getPerformanceDetail?.data?.model_portfolio_graph &&
    getPerformanceDetail.data.model_portfolio_graph.length > 0
      ? getPerformanceDetail.data.model_portfolio_graph[
          getPerformanceDetail.data.model_portfolio_graph.length - 1
        ].date
      : "";

  const percentageValue = getPerformanceDetail?.data?.investment_data[0]?.annualized_returns !== undefined
  ? `${getPerformanceDetail?.data?.investment_data[0]?.annualized_returns}%`
  : '0%';

  // const cardData = [
  //   {
  //     id: 1,
  //     name: "Capital",
  //     // value: "₹ 10,00,000",
  //     subheader1: "Invested",
  //     subheader1value:`₹ ${
  //       getPerformanceDetail?.data?.investment_data[0]?.amount_invested !==
  //       undefined
  //         ? Math.abs(getPerformanceDetail?.data?.investment_data[0]?.amount_invested
  //     ): 0 }`,
  //     subheader2:"Remaining",
  //     subheader2value:`₹ ${
  //       getPerformanceDetail?.data?.investment_data[0]?.remaining_cash !==
  //       undefined
  //         ? Math.abs(getPerformanceDetail?.data?.investment_data[0]?.remaining_cash
  //     ): 0 }`,
  //     // chart: "/images/ProfitChart.svg",
  //     display: "column",
  //     xs: 2.4,
     
  //   },
  
  //   {
  //     id: 2,
  //     name: "Current value",
  //     value: `₹ ${
  //       getPerformanceDetail?.data?.investment_data[0]?.current_value !==
  //       undefined
  //         ? Math.abs(getPerformanceDetail?.data?.investment_data[0]?.current_value)
  //         : 0
  //     }`,
  //     percentage: "",
  //     display: "column",
  //     xs: 2.4,
  //   },
  //   {
  //     id: 3,
  //     name: `${
  //       getPerformanceDetail?.data?.investment_data[0]?.profit !== undefined
  //         ? getPerformanceDetail?.data?.investment_data[0]?.profit >= 0
  //           ? "Profit"
  //           : "Loss"
  //         : ""
  //     }`,
  //     value: `₹ ${
  //       getPerformanceDetail?.data?.investment_data[0]?.profit !== undefined
  //         ? Math.abs(getPerformanceDetail?.data?.investment_data[0]?.profit)
  //         : 0
  //     }`,
  //     percentage: "",
  //     chart: getPerformanceDetail?.data?.investment_data[0]?.profit !== undefined
  //     ? getPerformanceDetail?.data?.investment_data[0]?.profit >= 0
  //     ? 'images/up_arrow.svg'
  //     : 'images/down_arrow.svg'
  //     : null,
  //     display: "column",
  //     xs: 2.4,
  //   },
  //   {
  //     id: 4,
  //     name: "Today Gain",
  //     value:`₹ ${
  //       getPerformanceDetail?.data?.investment_data[0]?.todays_gain !==
  //       undefined
  //         ? Math.abs(getPerformanceDetail?.data?.investment_data[0]?.todays_gain)
  //         : 0
  //     }`,
  //     percentage:
  //     `${
  //       getPerformanceDetail?.data?.investment_data[0]?.todays_gain_percentage !==
  //       undefined
  //         ? Math.abs(getPerformanceDetail?.data?.investment_data[0]?.todays_gain_percentage)
  //         : 0
  //     } %`,
  //     chart: getPerformanceDetail?.data?.investment_data[0]?.todays_gain_percentage !== undefined
  //     ? getPerformanceDetail?.data?.investment_data[0]?.todays_gain_percentage >= 0
  //       ? 'images/up_arrow.svg'
  //       : 'images/down_arrow.svg'
  //     : null,
  //     display: "column",
  //     xs: 2.4,
  //   },
  //   {
  //     id: 5,
  //     name: "Annualized returns",
  //     value: "",
  //     percentage: (
  //       <Text style={{ width: '100px',
  //       height: '150px' ,
  //       fontSize: "22px",
  //       fontWeight: 600,
  //       textWrap: "nowrap",
  //       marginTop:'-10px'}}>
  //         {percentageValue}
  //       </Text>
  //     ),
  //     display: "column",
  //     xs: 2.4,
  //   },
  // ];


  const dashboardCard = () => {
    let cardData = getPerformanceDetail?.data?.card_data || [];
    console.log("cardData",cardData);
    

    return cardData.map((card) => {
      
      let finalData = {
        display_heading: _get(card, "display_heading", "Card"),
        sequence: _get(card, "sequence", ""),
        data_1: {
          sub_heading: _get(card, "data_1.sub_heading", null),
          value: _get(card, "data_1.value", 0),
          value_type: _get(card, "data_1.value_type", ""),
          suffix_val: _get(card, "data_1.suffix_val", null),
          suffix_val_type: _get(card, "data_1.suffix_val_type", ""),
        },
        data_2: {
          sub_heading: _get(card, "data_2.sub_heading", null),
          value: _get(card, "data_2.value", null),
          value_type: _get(card, "data_2.value_type", ""),
          suffix_val: _get(card, "data_2.suffix_val", null),
          suffix_val_type: _get(card, "data_2.suffix_val_type", ""),
        }
      }

      return finalData;
    });
  };

  const investmentCardData = [
    {
      id: 2,
      name: "Model Portfolio",
      value1: "Amount invested",
      value2: "Current value",
      value3:
      `₹ ${
        getPerformanceDetail?.data?.model_portfolio_investment[0]?.amount_invested !==
        undefined
          ? getPerformanceDetail?.data?.model_portfolio_investment[0]?.amount_invested
          : 0
      }`,
      value4:
      `₹ ${
        getPerformanceDetail?.data?.model_portfolio_investment[0]?.current_value !==
        undefined
          ? getPerformanceDetail?.data?.model_portfolio_investment[0]?.current_value
          : 0
      }`,
      percentage: "20%",
      display: "column",
      xs: 4,
    },
    {
      id: 3,
      name: "Algo",
      value1: "Amount invested",
      value2: "Current value",
      value3:
      `₹ ${
        getPerformanceDetail?.data?.algo_investment[0]?.amount_invested !==
        undefined
          ? getPerformanceDetail?.data?.algo_investment[0]?.amount_invested
          : 0
      }`,
      value4:
      `₹ ${
        getPerformanceDetail?.data?.algo_investment[0]?.current_value !==
        undefined
          ? getPerformanceDetail?.data?.algo_investment[0]?.current_value
          : 0
      }`,
      percentage: "20%",
      display: "flex",
      xs: 4,
    },
    {
      id: 1,
      name: "Custom Portfolio",
      value1: "Amount invested",
      value2: "Current value",
      value3:
      `₹ ${
        getPerformanceDetail?.data?.custom_investment[0]?.amount_invested !==
        undefined
          ? getPerformanceDetail?.data?.custom_investment[0]?.amount_invested
          : 0
      }`,
      value4:
      `₹ ${
        getPerformanceDetail?.data?.custom_investment[0]?.current_value !==
        undefined
          ? getPerformanceDetail?.data?.custom_investment[0]?.current_value
          : 0
      }`,
      percentage: "20%",
      display: "flex",
      xs: 4,
    },
  ];

  const keyToLabel = {
    invested_value: "Amount Invested",
    current_value: "Current Value",
  };

  const colors = {
    invested_value: "lightblue",
    current_value: "lightgreen",
  };

  const stackStrategy = {
    stack: "total",
    area: true,
    stackOffset: "none",
  };

  const customize = {
    height: 300,
    legend: { hidden: false },
    margin: { top: 5 },
    stackingOrder: "descending",
  };

  const algo_invested_Data = [
    getPerformanceDetail?.data?.algo_progress_graph[0]?.invested_value || 0,
  ];
  const algo_profit_Data = [
    getPerformanceDetail?.data?.algo_progress_graph[0]?.profit || 0,
  ];
  const custom_invested_data = [
    getPerformanceDetail?.data?.custom_progress_graph[0]?.invested_value || 0,
  ];
  const custom_profit_data = [
    getPerformanceDetail?.data?.custom_progress_graph[0]?.profit || 0,
  ];
  const yLabels = [""];

  const handleClickDetail = (element, getPerformanceDetail) => {
    navigate("/investment-summary", {
      state: { cardData: element, performanceDetail: getPerformanceDetail , 
        BlockUserID:BlockUserID,
        BlockUserName:BlockUserName,
        BlockUserStatus:BlockUserStatus
      },
    });
  };

  useEffect(() => {
    getPerformance(user_id);
  }, [user_id,isValue]);

  useEffect(() => {
    setIsValue(false)
   }, [isValue]);

  return (
    <Stack style={{ marginTop: "10px" }}>
      
      <TradeCards data={dashboardCard() }cardsPerRow={getPerformanceDetail?.data?.cards_per_row} onClick={handleClickDetail} />
    

      <Grid sx={{ mb: 3 }} container spacing={2}>
        {investmentCardData.map((element, index) => (
          <Grid
            style={{cursor:'pointer'}}
            item
            xs={element.xs}
            key={`cards_index_${element.id}`}
            onClick={() =>
              handleClickDetail(element, getPerformanceDetail.data)
            }
          >
            <InvestmentTypeCard data={element} />
          </Grid>
        ))}
      </Grid>

      <Grid>
        {getPerformanceDetail?.data?.model_portfolio_graph &&
        getPerformanceDetail?.data?.model_portfolio_graph.length > 0 ? (
          <PaperStyled elevation={1}>
            <Text
              sx={{
                fontSize: "20px",
                fontWeight: 500,
                color: "Black",
                marginBottom: "20px",
              }}
            >
              Model portfolio progress
            </Text>
            <Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <BoxStyled
                  sx={{
                    backgroundColor: "lightgreen",
                  }}
                />
                <Text sx={{ ml: "8px", mr: "28px" }}>Amount invested</Text>
                <BoxStyled
                  sx={{
                    backgroundColor: "lightblue",
                  }}
                />
                <Text sx={{ ml: "8px" }}>Current value</Text>
              </Box>
              <Stack
                flexDirection="row"
                justifyContent="center"
                marginTop="20px"
              >
                <LineChart
                  xAxis={[
                    {
                      scaleType: "band",
                      data: getPerformanceDetail.data.model_portfolio_graph.map(
                        (e) => e.date
                      ),
                    },
                  ]}
                  Axis={[
                    {
                      dataKey: "year",
                      min: minDate,
                      max: maxDate,
                      tickFormatter: (value) => {
                        const date = new Date(value);
                        return `${(date.getMonth() + 1)
                          .toString()
                          .padStart(2, "0")}-${date
                          .getDate()
                          .toString()
                          .padStart(2, "0")}`;
                      },
                    },
                  ]}
                  series={Object.keys(keyToLabel).map((key) => ({
                    dataKey: key,
                    // label: keyToLabel[key],
                    color: colors[key],
                    showMark: false,
                    ...stackStrategy,
                  }))}
                  dataset={getPerformanceDetail.data.model_portfolio_graph}
                  {...customize}
                />
              </Stack>
            </Box>
          </PaperStyled>
        ) : (
          <div>No data available</div>
        )}
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6} sx={{ mb: 3, mt: 5 }} container>
          <PaperStyled elevation={1}>
            <Text
              sx={{
                fontSize: "20px",
                fontWeight: 500,
                color: "Black",
                marginBottom: "20px",
              }}
            >
              Algo (F&O) progress
            </Text>
            <Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <BoxStyled />
                <Text sx={{ ml: "8px", mr: "28px" }}>Amount invested</Text>
                <BoxStyled
                  sx={{
                    backgroundColor: "#9FC4FF",
                  }}
                />
                <Text sx={{ ml: "8px" }}>Profit made</Text>
              </Box>
              <Stack
                flexDirection="row"
                justifyContent="center"
                marginTop="20px"
              >
                <Box>
                  <StackBars
                    uData={algo_profit_Data}
                    pData={algo_invested_Data}
                    yLabels={yLabels}
                  />
                </Box>
              </Stack>
            </Box>
          </PaperStyled>
        </Grid>
        <Grid item xs={6} sx={{ mb: 3, mt: 5 }} container>
          <PaperStyled elevation={1}>
            <Text
              sx={{
                fontSize: "20px",
                fontWeight: 500,
                color: "Black",
                marginBottom: "20px",
              }}
            >
              Custom progress
            </Text>
            <Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <BoxStyled />
                <Text sx={{ ml: "8px", mr: "28px" }}>Amount invested</Text>
                <BoxStyled
                  sx={{
                    backgroundColor: "#9FC4FF",
                  }}
                />
                <Text sx={{ ml: "8px" }}>Profit made</Text>
              </Box>
              <Stack
                flexDirection="row"
                justifyContent="center"
                marginTop="20px"
              >
                <Box>
                  <StackBars
                    uData={custom_invested_data}
                    pData={custom_profit_data}
                    yLabels={yLabels}
                  />
                </Box>
              </Stack>
            </Box>
          </PaperStyled>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default Performance;
