import _get from "lodash/get";




const commonParser = {};


commonParser.addUserDetails = (response)=>{

    if (!response && !response.result) {
		return {};
	}

    if(response.result){
        response = response.result;
    }
    let result = {};
    let currentStage = _get(response ,"current_stage",null);
    let onboardStatus = _get(response ,"onboard_status",null)
    if(!onboardStatus){
        if(currentStage <= 2){
            onboardStatus = "INPROGRESS";
        }
        if(currentStage == 3){
            onboardStatus = "PENDING";
        }
    }

    result = {
        user_id: _get(response ,"user_id",null),
        name:_get(response ,"name",null),
        email:_get(response ,"email",null),
        family_id:_get(response ,"family_id",null),
        onboard_status: onboardStatus,
        current_stage: currentStage,
        onboard_status_reason:_get(response, "onboard_status_reason",null),
        role_id:_get(response ,"role_id",null),
        role_name:_get(response ,"role_name",null),
        is_admin:_get(response ,"is_admin",null),
        is_email_verified:_get(response ,"is_email_verified",null),
        is_mobile_verified:_get(response ,"is_mobile_verified",null),
        is_broker_missing:_get(response ,"is_broker_missing", null),
        mappedTo:_get(response,"mappedTo",null),
        jwtToken:_get(response ,"jwtToken",null),
    }

    return result;

    

}



export { commonParser }