import React, { useEffect, useRef, useState } from "react";
import TradeCard from "components/dashboard/TradeCard";
import Box from "components/common/Box";
import Grid from "components/common/Grid";
import Stack from "components/common/Stack";
import InvestmentTypeCard from "components/dashboard/InvestmentTypeCard";
import InvestmentStats from "components/dashboard/InvestmentStats";
import DashboardHeader from "components/dashboard/DashboardHeader";
import { dummyTableData, columns } from "helpers/constants";
import CustomGrid from "components/datagrid/CustomGrid";
import Text from "components/common/Text";
import Chip from "@mui/material/Chip";
import TradeCallStats from "components/dashboard/TradeCallStats";
import { dashboardApiAction } from "stores/redux/apiSlices/Customer DashBoard/dashboardApiSlice";
import { styled } from "@mui/system";
import { customersApiAction } from "stores/redux/apiSlices/customers/customersApiSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Paper } from "@mui/material";
import ViewCustomer from "components/customerSidebar/ViewCustomer";
import TextField from "components/common/TextField";
import dayjs from "dayjs";
import { localStore } from "stores/localStore";
import { commonActions } from "stores/redux/slices/commonSlice";
import debounce from "lodash.debounce";
import { tradesApiAction } from "stores/redux/apiSlices/trades/tradesApiSlice";
import { modelTableColumns } from "helpers/constants";
import CustomDataGrid from "components/datagrid/CustomGrid";
import { uuid } from "helpers/utility";
import {
  StyledDataGrid,
  ChipStyle,
  tableHeaderData,
  graphCardData,
  graphCardData1,
  ActiveChipStyle,
} from "components/dashboard/DahboradConstant";
// import { addCustomerApiAction } from "stores/redux/apiSlices/addCustomerApiSlice";
// import { CustomerPortalOnboardApiAction } from "stores/redux/slices/customerPortalOnboardSlice";
import { validationHelper } from 'helpers/validation';

const DashBoardContainer = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [customerId, setCustomerId] = useState(0);
  const [getCustomerSearchingData] =
    customersApiAction.getCustomerSearchingData();
  const [customersData, setCustomersData] = useState([]);
  const [userName, setUserName] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [totalCount, setTotalCount] = useState("");
  const [tradesDataApi, setTradesDataApi] = useState("");
  const [productIdNo, setProductIdNo] = useState(1);

  const [postData, setPostData] = useState({
    search: "",
    capital_from: "",
    capital_to: "",
    investment_type: "",
    plan_status: "",
    risk_profile: "",
    skip: "0",
    take: "10",
    is_dashboard: "0",
  });
  const [activeButton, setActiveButton] = useState(1);
  const [getTradesListing] = tradesApiAction.getTradesApi();

  const select = useSelector((state) => state);
  const userDetails = useSelector((state) => state.commonReducer.userDetails);

  //useEffect
  useEffect(() => {
    getTrades();
  }, []);

  // Get Api details methods
  const getTrades = async (productId = 1) => {
    // Default productId to 1
    let res = await getTradesListing({ product_id: productId ,noGlobalLoading: true});
    if(res?.data){
      const {
        data,
        totalRecord
      } = res.data
      if (data) {
        setTradesDataApi(data);
      }
    }
  };

  const parseTradesData = (data) => {
    let parsedData = data?.map((item) => {
      return {
        id: uuid(),
        customerName: item.customer_name ?? "",
        stockName: item.stock_name ?? "",
        quantity: item.quantity ?? "",
        buyDate: item.buy_date ?? "",
        sellDate: item.sell_date ?? "",
        amountInvested: item.amount_invested ?? "",
        totalGains: item.total_gains ?? "",
        amountAfterGains: item.amount_after_gains ?? "",
        todaysChange: item.todays_change ?? "",
        taxPl: item.tax_pl ?? "",
        riskProfile: item.risk_profile ?? "",
      };
    });
    return parsedData;
  };
  const handleButtonClick = async (productId) => {
    setActiveButton(productId);
    // Set productId directly to the selected productId
    setProductIdNo(productId);
    console.log(productId, "paramproductId");
    await getTrades(productId); // Use productId directly in getTrades function
  };

  const handleNavigation = () => {
    navigate("/trades", { state: { productIdNo } });
  };
  useEffect(() => {
    // Retrieve the user's name from local storage
    // const storedUserName = localStorage.getItem("userName");

    if (userDetails) {
      setUserName(userDetails.name);
    }
  }, [userDetails.name]);
  

  const searchingToServer = async () => {
    try {
      const result = await getCustomerSearchingData(postData);
      setCustomersData(result?.data?.result?.customers);
      setTotalCount(result?.result?.customer_count);
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const handleInputChange = (e, field) => {
    const inputValue = e.target.value.trim();

    setPostData((prevData) => ({
      ...prevData,
      [field]: inputValue,
    }));
  };

  useEffect(() => {
    debouncedFetchData(postData.search);
  }, [postData.search]);

  const debouncedFetchData = debounce(searchingToServer, 1000);

  const filterTableData =
    select?.toast?.filterTableData?.data?.result?.customers;

  const handleDateRangeChange = (newDateRange) => {
    setStartDate(newDateRange[0]);
    setEndDate(newDateRange[1]);
  };
  const payload = {
    startDate: startDate ? dayjs(startDate).format("YYYY-MM-DD") : null,
    endDate: endDate ? dayjs(endDate).format("YYYY-MM-DD") : null,
  };

  const [dashboardRefetch,{ data: dashboardData = {} }] =
    dashboardApiAction.getDashboardApi();

  useEffect(() => {
    if(payload && payload.startDate && payload.endDate){
      dashboardRefetch(payload);
    }
    if (
      dashboardData &&
      dashboardData.sebiregno &&
      dashboardData.baslNo &&
      dashboardData.expirydate
    ) {
      const { sebiregno, baslNo, expirydate } = dashboardData;
      const dataToStore = { sebiregno, baslNo, expirydate };

      // Dispatch the action to update the Redux store
      dispatch(commonActions.setDashboardData(dataToStore));

      localStore.setHeaderData(dataToStore);

      // Log the stored data from local storage
      const storedData = localStore.getHeaderData();
    }
  }, [dashboardData]);

  function formatValue(value) {
    // Check if value is not null or undefined
    if (value !== null && value !== undefined) {
      // Convert the number to an Indian number format
      return value.toLocaleString("en-IN", {});
    }
    // Return an empty string or another default value if needed
    return "";
  }

  // Example usage

  const cardData = [
    {
      id: 1,
      name: "Trade Calls",
      value: `${validationHelper.roundDecimal(formatValue(dashboardData?.tradecall))}`,
      chart: "/images/ProfitChart.svg",
      display: "column",
      xs: 4,
    },
    {
      id: 2,
      name: "Total profit of customers",
      value: ` ₹ ${validationHelper.roundDecimal(formatValue(dashboardData?.totalprofit))}`,
      percentage: `${validationHelper.roundDecimal(dashboardData?.totalprofitpercent)}%`,
      chart:
        dashboardData?.totalprofitpercent >= 0
          ? "/images/ProfitChart.svg"
          : "/images/LossChart.svg",
      color: dashboardData?.totalprofitpercent >= 0 ? "green" : "red",
      display: "flex",
      xs: 4,
    },
    {
      id: 3,
      name: "Tax P/L",
      value: ` ₹ ${validationHelper.roundDecimal(formatValue(dashboardData?.taxpl))} `,
      percentage: `${validationHelper.roundDecimal(dashboardData?.taxplpercent)}%`,
      chart:
        dashboardData?.taxplpercent >= 0
          ? "/images/ProfitChart.svg"
          : "/images/LossChart.svg",
      color: dashboardData?.taxplpercent >= 0 ? "green" : "red",
      xs: 4,
    },
  ];

  const investmentCardData = (dashboardData?.products || []).map((product) => ({
    id: 1,
    name: product.productname,
    value1: "Customers",
    value2: "Amount invested",
    value3: `${validationHelper.roundDecimal(product.customercount)}`,
    value4: `₹ ${validationHelper.roundDecimal(formatValue(product.amount))}`,
    display: "column",
    xs: 4,
    percent: `${validationHelper.roundDecimal(product.percent)}%`,
    color: product.percent >= 0 ? "green" : "red",
  }));
  if (!dashboardData?.tradecallsstats) {
    return (
      <div>
        {" "}
        <DashboardHeader
          handleDateRangeChange={handleDateRangeChange}
          userName={userName}
        />
      </div>
    );
  }

  const pieData = (dashboardData?.tradecallsstats || []).map(
    (TradeCallStats) => [
      { id: 0, value: TradeCallStats.noofcalls },
      { id: 1, value: TradeCallStats.actiontaken },
    ]
  );
  const investmentChart = (dashboardData?.investmentstats || []).map(
    (investmentstats) => ({
      profit: investmentstats.amount,
      amount: investmentstats.profit,
    })
  );

  const CustomerFooter = () => {
    return (
      <>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ padding: "12px 24px 16px 24px" }}
        >
          <Text
            variant="small"
            color="primary"
            sx={{
              textDecoration: "underline",
              fontWeight: 500,
              lineHeight: "20px",
              cursor: "pointer",
            }}
            onClick={() => navigate("/customers")}
          >
            View All
          </Text>
        </Stack>
      </>
    );
  };

  const TradeHeader = ({ labelCount }) => {
    // Destructure labelCount from props
    return (
      <>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box sx={{ p: 2, width: "50%" }}>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Text
                sx={{ color: "primary", mr: 1, fontWeight: 500 }}
                color="primary"
                variant="button"
              >
                Trades{" "}
              </Text>
              <Chip color="secondary" label={labelCount}></Chip>{" "}
              {/* Use labelCount */}
            </Stack>
            <Text variant="small" sx={{ color: "#667085" }}>
              Here you can view a trades
            </Text>
          </Box>
          <Box
            px={5}
            sx={{
              width: "50%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              sx={{
                height: "37px",
                width: "148px",
                fontSize: "14px",
                borderRadius: "8px",
                fontWeight: 500,
                border: "1px solid #142E56",
                color: activeButton === 2 ? "#F7F8FF" : "#101828",
                backgroundColor: activeButton === 2 ? "#142E56" : "white",
                "&:hover": {
                  backgroundColor: activeButton === 2 ? "#142E56" : "lightgrey",
                },
              }}
              onClick={() => handleButtonClick(2)}
            >
              Model portfolio
            </Button>
            <Button
              sx={{
                height: "37px",
                width: "148px",
                fontSize: "14px",
                borderRadius: "8px",
                fontWeight: 500,
                border: "1px solid #142E56",
                color: activeButton === 3 ? "#F7F8FF" : "#101828",
                backgroundColor: activeButton === 3 ? "#142E56" : "white",
                "&:hover": {
                  backgroundColor: activeButton === 3 ? "#142E56" : "lightgrey",
                },
              }}
              onClick={() => handleButtonClick(3)}
            >
              Algo
            </Button>
            <Button
              sx={{
                width: "148px",
                fontSize: "14px",
                height: "37px",
                fontWeight: 500,
                borderRadius: "8px",
                border: "1px solid #142E56",
                color: activeButton === 1 ? "#F7F8FF" : "#101828",
                backgroundColor: activeButton === 1 ? "#142E56" : "white",
                "&:hover": {
                  backgroundColor: activeButton === 1 ? "#142E56" : "lightgrey",
                },
              }}
              onClick={() => handleButtonClick(1)}
            >
              Custom
            </Button>
          </Box>
        </Box>
      </>
    );
  };

  const TradeFooter = (productId) => {
    return (
      <>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ padding: "12px 24px 16px 24px" }}
        >
          <Text
            variant="small"
            color="primary"
            sx={{
              textDecoration: "underline",
              fontWeight: 500,
              lineHeight: "20px",
              cursor: "pointer",
            }}
            onClick={handleNavigation}
          >
            View all
          </Text>
        </Stack>
      </>
    );
  };

  return (
    <>
      <DashboardHeader
        handleDateRangeChange={handleDateRangeChange}
        userName={userName}
      />

      <Grid sx={{ mb: 3 }} container spacing={2}>
        {cardData.map((element, index) => (
          <Grid item xs={element.xs} key={`cards_index_1_${index}`}>
            <TradeCard data={element} />
          </Grid>
        ))}
      </Grid>

      <Grid sx={{ mb: 3 }} container spacing={2}>
        {investmentCardData.map((element, index) => (
          <Grid item xs={element.xs} key={`cards_index_2_${index}`}>
            <InvestmentTypeCard data={element} />
          </Grid>
        ))}
      </Grid>

      <Grid sx={{ mb: 3 }} container spacing={2}>
        {graphCardData.map((element, index) => (
          <Grid item xs={element.xs} key={`cards_index_3_${index}`}>
            <InvestmentStats
              data={element}
              tradecallsstats={dashboardData?.tradecallsstats}
            />
          </Grid>
        ))}
        {/* {graphCardData1.map((element, index) => (
          <Grid item xs={element.xs} key={`cards_index_4_${index}`}>
            <TradeCallStats data={element} investmentChart={investmentChart} />
          </Grid>
        ))} */}
      </Grid>
      <Paper sx={{ borderRadius: "16px", mb: 4, mt: 2, height: "auto" }}>
        <Box style={{ padding: "15px" }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={1.5}
            sx={{ width: "100%" }}
            alignItems="center"
            style={{ marginTop: "10px" }}
          >
            <Box>
              <Stack direction="row">
                <Text fontSize="20px" fontWeight="600">
                  Customers
                </Text>
                <Chip
                  style={{
                    marginLeft: "5px",
                    height: "25px",
                    width: "100%",
                    textAlign: "center",
                    marginTop: "3px",
                  }}
                  color="secondary"
                  label={
                    <Text style={{ fontSize: "12px" }}>
                      {customersData?.length} customers
                    </Text>
                  }
                ></Chip>
              </Stack>
              <Text fontSize="15px" fontWeight="400" color="#667085"></Text>
            </Box>
          </Stack>
        </Box>
        <Box px="15px">
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={1.5}
            sx={{ width: "100%" }}
            alignItems="center"
            style={{ marginTOp: "50px" }}
          >
            <Box style={{ width: "500px", marginTop: "10px" }}>
              <Stack direction="row">
                <TextField
                  sx={{ maxWidth: "500px" }}
                  placeholder="Search"
                  value={postData.search}
                  name="search"
                  onChange={(e) => handleInputChange(e, "search")}
                />
              </Stack>
            </Box>
          </Stack>
        </Box>
        <Stack style={{ marginTop: "15px" }}>
          <StyledDataGrid
            sx={{ cursor: "pointer" }}
            autoHeight
            pagination={false}
            list={customersData}
            // list={filterTableData?.length === 0?customersData : filterTableData}
            columnHeaderHeight={45}
            rowHeight={72}
            columns={tableHeaderData}
            footer={CustomerFooter}
            // rowCount={filterTableData?.length === 0?customersData?.length : filterTableData?.length}
            rowCount={totalCount}
            getRowId={(row) => row?.user_id}
            getRowClassName={(params) => {
              return params?.row?.approve
                ? "super-app-theme--approve"
                : "super-app-theme--not-approve";
            }}
          />
        </Stack>
        {customerId === 0 ? (
          ""
        ) : (
          <ViewCustomer
            isDrawerOpen={isDrawerOpen}
            setIsDrawerOpen={setIsDrawerOpen}
            customerId={customerId}
            setCustomerId={setCustomerId}
            // viewData={viewData}
          />
        )}
      </Paper>

      <Box>
        {activeButton === 3 && (
          <CustomDataGrid
            list={tradesDataApi}
            columnHeaderHeight={46}
            rowHeight={46}
            columns={modelTableColumns}
            count={tradesDataApi.length}
            isLoading={false}
            isCalled={true}
            header={() =>
              TradeHeader({
                labelCount: tradesDataApi.length,
              })
            }
            footer={TradeFooter}
            pagination={false}
          />
        )}
        {activeButton === 2 && (
          <CustomDataGrid
            list={tradesDataApi}
            columnHeaderHeight={46}
            rowHeight={46}
            columns={modelTableColumns}
            count={tradesDataApi.length}
            isLoading={false}
            isCalled={true}
            header={() =>
              TradeHeader({
                labelCount: tradesDataApi.length,
              })
            }
            footer={TradeFooter}
            pagination={false}
          />
        )}
        {activeButton === 1 && (
          <CustomDataGrid
            list={tradesDataApi}
            columnHeaderHeight={46}
            rowHeight={46}
            columns={modelTableColumns}
            count={tradesDataApi.length}
            isLoading={false}
            isCalled={true}
            header={() =>
              TradeHeader({
                labelCount: tradesDataApi.length,
              })
            }
            footer={TradeFooter}
            pagination={false}
          />
        )}
      </Box>
    </>
  );
};

export default DashBoardContainer;
