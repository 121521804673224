import { createApi } from "@reduxjs/toolkit/query/react";
import { apiSliceInterceptor } from "stores/redux/apiSliceInterceptor";
import { addCustomerParser } from "stores/redux/apiParser/addCustomerParser";

const addCustomerApi = createApi({
  reducerPath: "addCustomerRIA",
  baseQuery: apiSliceInterceptor.baseQueryWithInterceptor,
  tagTypes: [
    "ADD_CUSTOMER",
    "FAMILY",
    "PLANS",
    "CUSTOMER_DETAILS",
    "PRODUCT_DETAILS",
    "CUSTOMER_SUMMARY",
  ],
  // const reducerUserId = useSelector((state)=> state?.customerOnboarding?.userId);
  endpoints: (qb) => ({
    getRiskAssesment: qb.query({
      query: (noGlobalLoading = true) => ({
        url: "api/customer/risk-assessment",
        params: { noGlobalLoading },
      }),
      transformResponse: addCustomerParser.riskAssesmentQts,
      providesTags: ["ADD_CUSTOMER"],
    }),

    getPreviousAnswer: qb.query({
      query: (payload) => `api/customer/selected-previous-answer/${payload.userId}`,
      transformResponse: (res) => res.result,
      providesTags: ["ADD_CUSTOMER"],
    }),
    getFamilyDetails: qb.query({
      query: () => "api/customer/family-groups",
      transformResponse: (res) => res.result,
      providesTags: ["FAMILY"],
    }),
    getPlanDetails: qb.query({
      query: () => "api/customer/plans",
      transformResponse: (res) => res.result,
      providesTags: ["PLANS"],
    }),
    getCustomerDetails: qb.query({
      query: (payload) => `api/customer/get-details/${payload.user_id}`,
      transformResponse: (res) => res.result,
      providesTags: ["CUSTOMER_DETAILS"],
    }),
    getProductDetails: qb.query({
      query: () => `api/customer/products`,
      transformResponse: (res) => res.result,
      providesTags: ["PRODUCT_DETAILS"],
    }),
    getCustomerSummary: qb.query({
      query: (payload) => `api/customer/get-details/${payload.userId}`,
      transformResponse: addCustomerParser.customerSummary,
      providesTags: ["CUSTOMER_SUMMARY"],
    }),
    getCustomerRiskProfile: qb.query({
      query: (payload) => `api/customer/get-customer-risk/${payload.userId}`,
      transformResponse: (res) => res.result,
    }),
    sendOtp: qb.mutation({
      query: (payload) => ({
        url: "api/customer/send-otp",
        method: "POST",
        body: payload,
      }),
    }),
    confirmEmailOtp: qb.mutation({
      query: (payload) => ({
        url: "api/customer/verify-otp",
        method: "POST",
        body: payload,
      }),
    }),
    confirmPhoneOtp: qb.mutation({
      query: (payload) => ({
        url: "api/customer/verify-otp",
        method: "POST",
        body: payload,
      }),
    }),
    approvedCustomer: qb.mutation({
      query: (payload) => ({
        url: "api/customer/update-customer-approval-by-ria",
        method: "PUT",
        body: payload,
      }),
    }),
    submitRiskDetails: qb.mutation({
      query: ({answers, userId}) => {
        return {
          url: `api/customer/update-risk-assessment/${userId}`,
          method: "post",
          body: {
            answers: answers,
          },
        };
      },
    }),
    updateRiskProfile: qb.mutation({
      query: (payload) => {
        return {
          url: `api/customer/update-manual-risk-profile/${payload.userId}`,
          method: "post",
          body: {
            risk_profile_id: payload.risk_profile_id,
          },
        };
      },
    }),
    updateInvestmentDetails: qb.mutation({
      query: (payload) => {
        return {
          url: `api/customer/update-investment-details/${payload.userId}`,
          method: "post",
          body: {
            ...payload.investmentDetails,
          },
        };
      },
    }),
    updateOtherDetails: qb.mutation({
      query: (payload) => ({
        url: `api/customer/update-other-details/${payload.userId}`,
        method: "post",
        body: {
          ...payload.otherDetails,
        },
      }),
    }),
    downloadDocument: qb.mutation({
      query: (payload) => ({
        url: `api/customer/download-document/${payload.userId}`,
        method: "post",
        body: {
          ...payload.otherDetails,
        },
      }),
      transformResponse: (response) => response.result,
    }),
  }),
});

export const addCustomerApiReducer = addCustomerApi.reducer;

export const addCustomerApiAction = {
  middleware: addCustomerApi.middleware,
  reducerPath: addCustomerApi.reducerPath,
  getRiskAssesment: addCustomerApi.useGetRiskAssesmentQuery,
  getPreviousAnswer: addCustomerApi.useLazyGetPreviousAnswerQuery,
  getFamilyDetails: addCustomerApi.useLazyGetFamilyDetailsQuery,
  getPlanDetails: addCustomerApi.useLazyGetPlanDetailsQuery,
  getCustomerDetails: addCustomerApi.useGetCustomerDetailsQuery,
  getProductDetails: addCustomerApi.useGetProductDetailsQuery,
  getCustomerSummary: addCustomerApi.useLazyGetCustomerSummaryQuery,
  getCustomerRiskProfile: addCustomerApi.useLazyGetCustomerRiskProfileQuery,
  sendOtp: addCustomerApi.useSendOtpMutation,
  confirmEmailOtp: addCustomerApi.useConfirmEmailOtpMutation,
  confirmPhoneOtp: addCustomerApi.useConfirmPhoneOtpMutation,
  approvedCustomer: addCustomerApi.useApprovedCustomerMutation,
  submitRiskDetails: addCustomerApi.useSubmitRiskDetailsMutation,
  updateRiskProfile: addCustomerApi.useUpdateRiskProfileMutation,
  updateInvestmentDetails: addCustomerApi.useUpdateInvestmentDetailsMutation,
  updateOtherDetails: addCustomerApi.useUpdateOtherDetailsMutation,
  downloadDocument: addCustomerApi.useDownloadDocumentMutation,
};
