import React, { useEffect, useState } from "react";
import Grid from "@mui/system/Unstable_Grid";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import Otp from "components/Login/Otp";
import SignUp from "components/Login/SignUp";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { signUpApiAction } from "stores/redux/apiSlices/SignUp/signUpApiSlice";
import { validationHelper } from "helpers/validation";
import useOtpTimer from "hooks/OtpTimer";
import { addCustomerActions } from "stores/redux/slices/addCustomerSlice";
import { customerOnboardingActions } from "stores/redux/slices/CustomerOnboardingSlice";

// Store
import { authApiAction } from "stores/redux/apiSlices/authApiSlice";
import { toastActions } from "stores/redux/slices/toastSlice";
import { localStore } from "stores/localStore";

// parser
import { commonParser } from "stores/redux/apiParser/commonParser";
import { commonActions } from "stores/redux/slices/commonSlice";
import { appConstants } from "helpers/constants/appConstants";

// Constant
const USER_ROLES = appConstants.userRoles;

import { useLocation } from "react-router-dom";

function SignUpContainer() {
  const [basicDetails, setBasicDetails] = useState({
    name: "",
    email: "",
    phoneNo: "",
    emailOtp: "",
    phoneOtp: "",
    code: "",
  });
  const [basicDetailsError, setBasicDetailsError] = useState({
    email: "",
    phoneNo: "",
    emailOtp: "",
    phoneOtp: "",
  });

  const [isOtpSend, setIsOtpSend] = useState(false);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [isOTPSent, setIsOTPSent] = useState(false);
  const [otpId, setOtpId] = useState(null);
  const [otpError, setOtpError] = useState("");
  const [userId, setUserId] = useState(null);
  const [isEmailOtpSend, setIsEmailOtpSend] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isPhoneOtpSend, setIsPhoneOtpSend] = useState(false);

  // Apis
  const [handleSendOtpApi] = authApiAction.sendOtp();
  const [handleVerifyUser] = authApiAction.verifyUser();
  const [handleResendOtpApi] = authApiAction.resendOtp();

  // hooks
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Use useNavigate hook
  const location = useLocation();

  const reducerUserId = useSelector(
    (state) => state?.customerOnboarding?.userId
  );
  const {
    seconds: emailSec,
    isActive: emailOtpActive,
    startTimer: startEmailTimer,
    resetTimer: resetEmailTimer,
  } = useOtpTimer();

  const {
    seconds: phoneSec,
    isActive: phoneOtpActive,
    startTimer: startPhoneTimer,
    resetTimer: resetPhoneTimer,
  } = useOtpTimer();

  //useEffect
  useEffect(() => {
    if (emailSec === 0) {
      resetEmailTimer();
    }
  }, [emailSec]);

  useEffect(() => {
    if (phoneSec === 0) {
      resetPhoneTimer();
    }
  }, [phoneSec]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get("code");
    const userType = params.get("user_type");
    console.log("userType",userType);

    if (userType) {
      localStorage.setItem("userType", userType);
    }
    const newBasicDetails = { ...basicDetails };
    setBasicDetails({ ...newBasicDetails, ["code"]: code });
  }, []);

  const createOtpPayload = (input) => {
    let payload = {};
    let type;

    if (/^\d+$/.test(input)) {
      payload = {
        mobile: input,
        type: "mobile",
      };
      type = "mobile";
    } else {
      payload = {
        email: input,
        type: "email",
      };
      type = "email";
    }

    return { payload, type };
  };

  const handlePhoneChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setPhone(value);
  };

  const iconChange = () => {
    console.log("clicked");
    setIsOtpSend(false);
    setPhone("");
  };

  const handleOtpChange = (otpValue) => {
    if (!isNaN(otpValue)) {
      // If it's a number and not empty, update the state
      setOtp(otpValue);
    }
  };

  const handleLoginSubmit = () => {
    const { payload, type } = createOtpPayload(phone);

    payload.otp = otp; // Add the OTP to the payload

    console.log("Login Submit Payload:", payload);

    handleVerifyUser(payload)
      .unwrap()
      .then((response) => {
        console.log("API Response:", response);
        dispatch(
          toastActions.setToastData({
            message: response.message,
            variant: "error",
          })
        );
        if (response) {
          dispatch(
            toastActions.setAuth({
              isAuthenticated: true,
            })
          );
          const userName = response.result.name;

          // Store token and name in local storage
          localStore.setToken(response.result.jwtToken);
          localStore.setName(userName);

          // Pass the user's name as a prop to the dashboard route
          navigate("/dashboard", { state: { userName } });
        }
      })
      .catch((error) => {
        console.error("Error in API call:", error);

        // Log the complete error object for inspection
        console.log("Complete error object:", error);

        if (error && error.data && error.data.message) {
          setOtpError(error.data.message);
        } else {
          // If no specific error message is available, use a generic one
          setOtpError("Failed to send OTP. Please try again.");
        }
      });
  };

  const handleResendOTP = () => {
    console.log("Box clicked!");
    setIsOTPSent(true);

    const { payload, type } = createOtpPayload(phone);

    console.log(payload, "respay--------");
    handleResendOtpApi(payload)
      .unwrap()
      .then((response) => {
        console.log("API Response:", response);

        if (response && response.data && response.data.id) {
          console.log("ID from API:", response.data.id);

          setOtpId(response.data.id);
          dispatch(
            toastActions.setToastData({
              message: response.message,
              variant: "error",
            })
          );
        }
      })
      .catch((error) => {
        console.error("Error in API call:", error);
      });
  };

  const handleSendOTP = () => {
    setIsOtpSend(true);
  };

  const handleBasicFormChange = (name, value) => {
    const newBasicDetails = { ...basicDetails };
    if (name === "phoneNo" || name === "emailOtp" || name === "phoneOtp") {
      if (isNaN(value)) {
        return;
      }
    }
    setBasicDetails({ ...newBasicDetails, [name]: value });
  };
  const handleEmailValidation = () => {
    let newError = { ...basicDetailsError };
    const emailValidation = validationHelper.email(basicDetails.email);
    newError.email = emailValidation.message;
    setBasicDetailsError(newError);
    return emailValidation.isValid;
  };

  const [sendOTPAction] = signUpApiAction.sendOtp();
  const [confirmEmailOtpAction] = signUpApiAction.confirmEmailOtp();
  const [confirmPhoneOtpAction] = signUpApiAction.confirmPhoneOtp();

  const handleEmailOtpSend = async () => {
    if (!handleEmailValidation()) {
      return;
    }
    const payload = {
      name: basicDetails.name,
      type: "email",
      email: basicDetails.email,
      mobile: "",
      user_type: "customer",
      user_id: "",
    };
    console.log(payload, "Rohit Payload");
    try {
      const res = await sendOTPAction(payload).unwrap();
      if (res.status === 1) {
        if (res?.result?.current_stage && Number(res.result.current_stage) > 0) {
          dispatch(
            toastActions.setToastData({
              message: "Email already exists",
              variant: "error",
            })
          );
          handleStepChange(Number(res.result.current_stage) + 1);
          return;
        }
        setIsEmailOtpSend(true);
        // if (emailOtpActive) {
        // 	resetEmailTimer();
        // } else {
        startEmailTimer(5);
        // }
      }
    } catch (err) {
      console.log("err:", err);
    }
  };
  

  const handleEmailOtpSubmit = async () => {
    if (!otpValidation({ name: "emailOtp", value: basicDetails.emailOtp })) {
      return;
    }
    const payload = {
      type: "email",
      email: basicDetails.email,
      mobile: "",
      otp: basicDetails.emailOtp,
      code: basicDetails.code,
    };
    try {
      let res = await confirmEmailOtpAction(payload);
      res = res.data;
      if (res.status === 1) {
        dispatch(
          customerOnboardingActions.setUserId({ userId: res.result.user_id })
        );
        setUserId(res.result.user_id);
        setIsEmailVerified(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handlePhoneOtpSubmit = async () => {
    if (!otpValidation({ name: "phoneOtp", value: basicDetails.phoneOtp })) {
      return;
    }
    const payload = {
      type: "mobile",
      email: "",
      mobile: basicDetails.phoneNo,
      otp: basicDetails.phoneOtp,
      user_id: userId,
    };
    try {
      const response = await confirmPhoneOtpAction(payload);
      console.log("phone submit res : ", response);
      // Parse response
      const userDetails = commonParser.addUserDetails(response?.data);

      if (!userDetails || !userDetails.user_id) {
        dispatch(
          toastActions.setToastData({
            message: "Something went wrong.",
            variant: "error",
          })
        );
        return;
      }

      // set user role code
      let userRoleCode;
      if (userDetails && userDetails.role_name) {
        let roleName = userDetails.role_name.toLowerCase();
        switch (roleName) {
          case "admin":
            userRoleCode = USER_ROLES.ADMIN.code;
            break;
          case "ria":
            userRoleCode = USER_ROLES.RIA.code;
            break;
          case "subbroker":
            userRoleCode = USER_ROLES.SUBBROKER.code;
            break;
          case "customer":
            userRoleCode = USER_ROLES.CUSTOMER.code;
            break;
        }
      }

      if (!userRoleCode) {
        dispatch(
          toastActions.setToastData({
            message: "User role not defined",
            variant: "error",
          })
        );
        return;
      }

      dispatch(commonActions.setUserDetails(userDetails));
      dispatch(commonActions.setUserRole(userRoleCode));
      dispatch(commonActions.setAuth(true));

      if (response.data.status === 1) {
        // Store token and name in local storage
        localStore.setToken(userDetails.jwtToken);

        // Pass the user's name as a prop to the dashboard route
        navigate("/customer/onboarding");
        // localStore.setToken(res.data.result.jwtToken);
        setIsBasicDetailsCompleted(true);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handlePhoneValidation = () => {
    let newError = { ...basicDetailsError };
    const mobileValidation = validationHelper.mobile(basicDetails.phoneNo);
    newError.phoneNo = mobileValidation.message;
    setBasicDetailsError(newError);
    return mobileValidation.isValid;
  };

  const otpValidation = ({ name, value }) => {
    let newBasicDetailsError = { ...basicDetailsError };

    const otpValidation = validationHelper.otp(value);
    newBasicDetailsError[name] = otpValidation.message;

    setBasicDetailsError(newBasicDetailsError);
    return otpValidation.isValid;
  };

  const handlePhoneOtpSend = async () => {
    if (!handlePhoneValidation()) {
      return;
    }
    const payload = {
      name: basicDetails.name,
      type: "mobile",
      email: basicDetails.email,
      mobile: basicDetails.phoneNo,
      user_type: "customer",
      user_id: userId,
    };
    try {
      const res = await sendOTPAction(payload).unwrap();
      if (res.status === 1) {
        setIsPhoneOtpSend(true);
        startPhoneTimer();
      }
    } catch (err) {
      console.log("err:", err);
    }
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{ maxWidth: "100vw", m: 0, p: 0, overflow: "hidden" }}
      >
        <Grid item xs={6} sx={{ p: 0, m: 0 }}>
          <Box
            sx={{
              height: "100vh",
              background: "url(/images/login-img.svg)",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <Box
              sx={{ position: "absolute", top: "50px", left: "50px", gap: 8 }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontWeight: 700,
                  fontSize: 48,
                  color: "white",
                  lineHeight: "normal",
                }}
              >
                FundzUp
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  color: "white",
                  fontSize: 20,
                }}
              >
                The most popular stock advisory platform.
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={6} sx={{ p: 0, m: 0 }}>
          {isOtpSend ? (
            <Otp
              onChange={handleOtpChange}
              onReset={iconChange}
              handleLoginSubmit={handleLoginSubmit}
              otp={otp}
              mobileNumberEmail={phone}
              resendOtp={handleResendOTP}
              otpError={otpError}
            />
          ) : (
            <SignUp
              handleBasicFormChange={handleBasicFormChange}
              // formData={basicDetails}
              formData={basicDetails}
              handleEmailOtpSubmit={handleEmailOtpSubmit}
              handlePhoneOtpSubmit={handlePhoneOtpSubmit}
              handleEmailOtpSend={handleEmailOtpSend}
              handlePhoneOtpSend={handlePhoneOtpSend}
              isEmailOtpSend={isEmailOtpSend}
              emailOtpActive={emailOtpActive}
              emailSec={emailSec}
              phoneSec={phoneSec}
              phoneOtpActive={phoneOtpActive}
              onChange={handlePhoneChange}
              phoneNo={phone}
              emailId={email}
              handleSendOTP={handleSendOTP}
              customerLogin={true}
              isEmailVerified={isEmailVerified}
              isPhoneOtpSend={isPhoneOtpSend}
              basicDetailsError={basicDetailsError}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default SignUpContainer;
