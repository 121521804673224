import React, { useState } from 'react';
import Box from 'components/common/Box';
import Text from 'components/common/Text';
import Stack from 'components/common/Stack';
import TextField from 'components/common/TextField';
import DatePicker from 'components/common/DatePicker';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const CustomAddFilter = (props) => {
  const { handleChange, filterData } = props;

  const handleSquareOffChange = (event) => {
    const value = event.target.value;
    handleChange({ name: 'isSquareOff', value: value });
  };


  return (
    <Stack spacing={2}>
      <Stack direction="row" alignItems="flex-end" spacing={2}>
        <Box sx={{ flex: 1 }}>
          <Text sx={{ mb: "6px" }}>Profit range (%)</Text>
          <TextField
            placeholder="Eg. 5%"
            name="profitFrom"
            onChange={(e) => {
              handleChange({ name: e.target.name, value: e.target.value });
            }}
            value={filterData.profitFrom}
          />
        </Box>
        <TextField
          sx={{ flex: 1 }}
          placeholder="Eg. 10%"
          name="profitTo"
          onChange={(e) => {
            handleChange({ name: e.target.name, value: e.target.value });
          }}
          value={filterData.profitTo}
        />
      </Stack>
      <Stack direction="row" alignItems="flex-end" spacing={2}>
        <Box sx={{ flex: 1 }}>
          <Text sx={{ mb: "6px" }}>Date range</Text>
          <DatePicker
            placeholder="Select Date"
            name="fromDate"
            onChange={(value) => {
              handleChange({ name: "fromDate", value: value });
            }}
            value={filterData.fromDate}
          />
        </Box>
        <DatePicker
          sx={{ flex: 1 }}
          placeholder="Select Date"
          name="toDate"
          onChange={(value) => {
            handleChange({ name: "toDate", value: value });
          }}
          value={filterData.toDate}
        />
      </Stack>
      <Stack direction="row" alignItems="flex-end" spacing={2}>
        <Box sx={{ flex: 1 }}>
          <Text sx={{ mb: "6px" }}>Square Off</Text>
          <RadioGroup
            value={filterData.isSquareOff}
            onChange={handleSquareOffChange}
            row
          >
            <FormControlLabel value={1} control={<Radio />} label="Yes" />
            <FormControlLabel value={0} control={<Radio />} label="No" />
          </RadioGroup>
        </Box>
      </Stack>
    </Stack>
  );
};

export default CustomAddFilter;
