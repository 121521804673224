const addCustomerParser = {};

addCustomerParser.riskAssesmentQts = (response) => {
	if (!response.result && !response.result.riskdata) {
		return [];
	}
	let result = response.result.riskdata;
	const qts = result.map((item) => {
		return {
			questionId: item?.question_id ?? null,
			questionDescription: item?.question_description ?? null,
		};
	});
	
	const textValuesParser = (textbox=[]) => {
		return textbox.map((tb) => ({
			question_textbox_info_id: tb.question_textbox_info_id,
			text_heading: tb.text_heading,
			text_placeholder: tb.text_placeholder,
		}));
	} 

	const optionsParser = (options=[]) => {
		return options.map((opt) => ({
			optionId: opt.option_id,
			optionDescription: opt.option_description,
			weightage: opt.weightage,
			isOnlySelect: opt.is_only_select,
			text_values: textValuesParser(opt.text_values),
		}));
	};


	const qtsOptions = result.map((item) => {
		return {
			questionId: item.question_id,
			questionDescription: item.question_description,
			isMultiselect: item.is_multiselect,
			options: optionsParser(item.options),
			// is_text: item.is_text,
			// textbox: textBoxParser(item.textbox)
		};
	});

	const res = {
		qts,
		options: qtsOptions,
	};
	return res;
};

addCustomerParser.customerSummary = (response) => {
	if (!response.result && !response.result) {
		return [];
	}

	response = response.result;

	let result = {};

	result.customerDetails = {
		name: response?.customerDetails[0]?.name ?? null,
		email: response?.customerDetails[0]?.email ?? null,
		mobile: response?.customerDetails[0]?.mobile ?? null,
		capital: response?.customerDetails[0]?.capital ?? null,
		current_stage: response?.customerDetails[0]?.current_stage ?? null,
		user_address: response?.customerDetails[0]?.user_address ?? null
	};

	result.riskAssessment = response?.riskAssessment;
	result.riskAssessmentManualRiskProfile = response?.riskAssessment_manual_risk_profile;
	result.riskAssessmentSuggestedRiskProfile = response?.riskAssessment_suggested_risk_profile;
	result.investmentDetails = {};

	let customType = response?.investmentDetails?.find((item) => item.product_id === 1);
	let modelType = response?.investmentDetails?.find((item) => item.product_id === 2);
	let algoType = response?.investmentDetails?.find((item) => item.product_id === 3);

	result.investmentDetails.custom = {
		productId: customType?.product_id ?? null,
		productName: customType?.product_name ?? null,
		investmentAmount: customType?.investment_amount ?? null,
		investmentPercent: customType?.investment_percent ?? null,
		isPercent: customType?.is_percent ?? null,
		cash: customType?.cash ?? null,
		isActive: customType?.is_active ?? null,
		
	};
	result.investmentDetails.modelType = {
		productId: modelType?.product_id ?? null,
		productName: modelType?.product_name ?? null,
		investmentAmount: modelType?.investment_amount ?? null,
		investmentPercent: modelType?.investment_percent ?? null,
		isPercent: modelType?.is_percent ?? null,
		isActive : modelType?.is_active ?? null,
	};

	let modelProduct =
		modelType?.product_category?.map((item) => {
			return {
				categoryName: item.category_name,
				investmentAmount: item.investment_amount,
				investmentPercent: item.investment_percent,
				isPercent: item.is_percent,
				isActive: item.is_active,

			};
		}) ?? [];

	result.investmentDetails.modelType.product = modelProduct;

	result.investmentDetails.algoType = {
		productId: algoType?.product_id ?? null,
		productName: algoType?.product_name ?? null,
		investmentAmount: algoType?.investment_amount ?? null,
		investmentPercent: algoType?.investment_percent ?? null,
		isPercent: algoType?.is_percent ?? null,
		cash: algoType?.cash ?? null,
		isActive: algoType?.is_active ?? null
	};

	result.otherDetails = {
		plan_name: response?.otherDetails[0]?.plan_name ?? null,
		plan_code: response?.otherDetails[0]?.plan_code ?? null,
		start_date: response?.otherDetails[0]?.start_date ?? null,
		end_date: response?.otherDetails[0]?.end_date ?? null,
		auto_trade: response?.otherDetails[0]?.auto_trade ?? null,
		customer_residency: response?.otherDetails[0]?.customer_residency ?? null,
		customer_category: response?.otherDetails[0]?.customer_category ?? null,
		customer_type: response?.otherDetails[0]?.customer_type ?? null,
		joining_date: response?.otherDetails[0]?.joining_date ?? null,
		family_group_name: response?.otherDetails[0]?.family_group_name ?? null,	
	};

	result.documentDetails = response?.documentDetails ?? [];

	console.log("result : ",result);
	return result;
};

export { addCustomerParser };
