import React, { useState } from "react";
import Box from "components/common/Box";
import Text from "components/common/Text";
import TextField from "components/common/TextField";
import Button from "components/common/Button";
import Card from "components/common/Card";
import Grid from "components/common/Grid";
import Radio from "components/common/Radio";
import Checkbox from "components/common/Checkbox";
import FileUpload from "components/common/FileUpload";
import MenuItem from "components/common/MenuItem";
import DatePicker from "components/common/DatePicker";
import Stack from "components/common/Stack";
import { Autocomplete, Link } from "@mui/material";
import { styled } from "@mui/material";
import StockDeleteIcon from "asset/icons/StockDeleteIcon";

import _get from "lodash/get";

const PreviousButton = styled(Button)(() => ({
  borderRadius: "8px",
  textTransform: "capitalize",
  padding: "18px 28px",
  marginRight: "20px",
  fontWeight: 600,
  width: "160px",
  border: "1.3px solid #142E56",
}));

const PlanText = styled(Text)(() => ({
  color: "#242424",
  fontSize: "14px",
  fontWeight: 500,
}));

const IndianText = styled(Text)(() => ({
  fontSize: "16px",
  fontWeight: 400,
  color: "#242424",
  marginRight: "20px",
}));

const CustomerCategoryText = styled(Text)(() => ({
  fontSize: "16px",
  fontWeight: 400,
  color: "#242424",
  marginRight: "20px",
}));

const CustomerTypeText = styled(Text)(() => ({
  color: "#242424",
  fontSize: "14px",
  fontWeight: 500,
  marginBottom: "12px",
}));

const AdvisoryCustomerText = styled(Text)(() => ({
  fontSize: "16px",
  fontWeight: 400,
  color: "#242424",
  marginRight: "8px",
}));

const JoiningDateText = styled(Text)(() => ({
  color: "#242424",
  fontSize: "14px",
  fontWeight: 500,
  marginBottom: "12px",
}));

const AutoTradeText = styled(Text)(() => ({
  color: "#242424",
  fontSize: "16px",
  fontWeight: 500,
  marginBottom: "6px",
}));

const PanCardText = styled(Text)(() => ({
  color: "#242424",
  fontSize: "14px",
  fontWeight: 500,
  marginBottom: "12px",
}));

const PanFormatText = styled(Text)(() => ({
  color: "#242424",
  fontSize: "12px",
  fontWeight: 500,
  marginBottom: "12px",
}));

const SubmitButton = styled(Button)(() => ({
  backgroundColor: "#142E56",
  color: "#FFF",
  fontSize: "14px",
  borderRadius: "8px",
  padding: "18px 28px",
  width: "260px",
  mb: 8,
}));

const PricingWrapper = styled(Box)(() => ({
  flexFlow: "row wrap",
  display: "flex",
  backgroundColor: "#e9e9e9",
  padding: "15px 15px",
  borderRadius: "7px",
  color: "gray",
}));

const PricingCard = styled(Box)(({ rightBorder }) => ({
  padding: "0px 30px",
  borderRight: rightBorder ? "1px solid #ababab" : "",
  "& > span": {
    color: "#000",
    fontWeight: "500",
  },
}));

function OtherDetails(props) {
  const {
    handleChange,
    handleStepChange,
    handleSubmit,
    formData,
    planList, // this is for plan Name list
    familyList,
    formError,
    downloadDocument,
    customerSummary,
    downloadAgreementTemplate
  } = props;

  React.useEffect(() => {
    handleChange({
      name: "customerType",
      value: "Advisory",
    });

    // auto scroll to top
    document.getElementById("mainAppWrapper").scrollTo(0, 0);
  }, []);

  let selectedPlanDetails;
  if (formData?.code && formData?.plan && formData?.plan?.subCategories) {
    selectedPlanDetails = formData.plan.subCategories.find(
      (p) => p.code === formData.code
    );
  }

  
  const doc = _get(customerSummary,"documentDetails",null);
  let docPan;
  let docAdhar;
  let docAgmt;

  if(doc && doc.length){
    doc.forEach( d => {
      if(d.document_name === "PAN_CARD"){
        docPan = d;
      }
      if(d.document_name === "AADHAR_CARD"){
        docAdhar = d;
      }
      if(d.document_name === "SIGN_AGREEMENT"){
        docAgmt = d;
      }
    })
  }
    
  return (
    <>
      <Box>
        <Card sx={{ p: 4, borderRadius: "16px", mb: "36px" }}>
          <Box>
            <Grid container spacing={4}>
              <Grid item md={6}>
                <Box sx={{ mb: "28px" }}>
                  <Text sx={{ color: "#242424", fontWeight: "600px" }}>
                    Other details
                  </Text>
                </Box>
              </Grid>
              <Grid item md={6}></Grid>

              <Grid item md={6}>
                <Box sx={{ width: "100%" }}>
                  <PlanText>Plan</PlanText>

                  <TextField
                    select
                    onChange={(e) => {
                      handleChange({
                        name: e.target.name,
                        value: e.target.value,
                      });
                    }}
                    name="plan"
                    value={formData.plan}
                  >
                    {planList?.map((plan) => (
                      <MenuItem key={plan.plan_id} value={plan}>
                        {plan.name}
                      </MenuItem>
                    ))}
                  </TextField>
                  {formError.plan && (
                    <Text variant="small" color="red" py={1}>
                      {formError.plan}
                    </Text>
                  )}
                </Box>
              </Grid>

              <Grid item md={6}>
                <Box sx={{ width: "100%" }}>
                  <PlanText>Plan code</PlanText>
                  <TextField
                    placeholder="Plan code"
                    value={formData?.code}
                    inputProps={{ style: { fontSize: "14px" } }}
                    select
                    onChange={(d) => {
                      handleChange({ name: "code", value: d.target.value });
                    }}
                    disabled={!formData.plan}
                    sx={{ borderRadius: "8px" }}
                  >
                    {formData.plan &&
                      formData.plan.subCategories.map((subcategory, index) => (
                        <MenuItem key={index} value={subcategory.code}>
                          {`${subcategory.code} - ${subcategory.frequency}`}
                        </MenuItem>
                      ))}
                  </TextField>
                </Box>
              </Grid>

              {/* Plan pricing */}
              {selectedPlanDetails ? (
                <Grid item md={12}>
                  <PricingWrapper>
                    <Box sx={{ color: "#000", fontWeight: "500" }}>
                      Pricing for the selected plan:{" "}
                    </Box>
                    <PricingCard rightBorder>
                      Modal Portfolio:{" "}
                      <span>&#8377; {selectedPlanDetails?.modelportfolio}</span>
                    </PricingCard>
                    <PricingCard rightBorder>
                      Custom: <span>&#8377; {selectedPlanDetails?.custom}</span>
                    </PricingCard>
                    <PricingCard>
                      Algo: <span>&#8377; {selectedPlanDetails?.algo}</span>
                    </PricingCard>
                  </PricingWrapper>
                </Grid>
              ) : null}

              <Grid item md={6}>
                <Box sx={{ width: "100%" }}>
                  <PlanText>Plan start date</PlanText>
                  <DatePicker
                    value={formData.planStartDate}
                    sx={{ width: "100%" }}
                    onChange={(d) => {
                      handleChange({ name: "planStartDate", value: d["$d"] });
                    }}
                  />
                  {formError.planStartDate && (
                    <Text variant="small" color="red" py={1}>
                      {formError.planStartDate}
                    </Text>
                  )}
                </Box>
              </Grid>

              <Grid item md={6}>
                <Box sx={{ width: "100%" }}>
                  <PlanText>Plan expiry date</PlanText>
                  <DatePicker
                    value={formData.planExpiryDate}
                    sx={{ width: "100%" }}
                    // readOnly
                    onChange={(d) => {
                      handleChange({
                        name: "planExpiryDate",
                        value: d["$d"],
                      });
                    }}
                  />
                  {formError.planExpiryDate && (
                    <Text variant="small" color="red" py={1}>
                      {formError.planExpiryDate}
                    </Text>
                  )}
                </Box>
              </Grid>

              <Grid item md={6}>
                <Box sx={{ width: "100%" }}>
                  <PlanText sx={{ mb: "12px" }}>Customer residency</PlanText>

                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <Radio
                      value="INDIAN"
                      name="customerResidency"
                      sx={{ mr: "8px" }}
                      checked={formData.customerResidency === "INDIAN"}
                      onChange={() => {
                        handleChange({
                          name: "customerResidency",
                          value: "INDIAN",
                        });
                      }}
                    />

                    <IndianText>Indian</IndianText>
                    <Radio
                      value="NON-INDIAN"
                      name="customerResidency"
                      sx={{ mr: "8px" }}
                      checked={formData.customerResidency === "NON-INDIAN"}
                      onChange={() => {
                        handleChange({
                          name: "customerResidency",
                          value: "NON-INDIAN",
                        });
                      }}
                    />

                    <IndianText>Non-indian</IndianText>

                    <Radio
                      value="NRI"
                      name="customerResidency"
                      sx={{ mr: "8px" }}
                      checked={formData.customerResidency === "NRI"}
                      onChange={() => {
                        handleChange({
                          name: "customerResidency",
                          value: "NRI",
                        });
                      }}
                    />

                    <IndianText>NRI</IndianText>
                  </Box>
                  {formError.customerResidency && (
                    <Text variant="small" color="red" py={1}>
                      {formError.customerResidency}
                    </Text>
                  )}
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box sx={{ width: "100%" }}>
                  <PlanText sx={{ mb: "10px" }}>Customer category</PlanText>

                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <Radio
                      value="Individual"
                      name="customerCategory"
                      sx={{ mr: "8px" }}
                      checked={formData.customerCategory === "Individual"}
                      onChange={() => {
                        handleChange({
                          name: "customerCategory",
                          value: "Individual",
                        });
                      }}
                    />

                    <IndianText>Individual</IndianText>
                    <Radio
                      value="Non-Individual"
                      name="customerCategory"
                      sx={{ mr: "8px" }}
                      checked={formData.customerCategory === "Non-Individual"}
                      onChange={() => {
                        handleChange({
                          name: "customerCategory",
                          value: "Non-Individual",
                        });
                      }}
                    />

                    <IndianText>Non - Individual</IndianText>
                  </Box>
                  {formError.customerCategory && (
                    <Text variant="small" color="red" py={1}>
                      {formError.customerCategory}
                    </Text>
                  )}
                </Box>
              </Grid>

              <Grid item md={6}>
                <Box sx={{ mb: "0px" }}>
                  <CustomerTypeText>
                    Customer type
                  </CustomerTypeText>
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <Radio
                      value="Advisory"
                      sx={{ mr: "8px" }}
                      checked={formData.customerType === "Advisory"}
                      onChange={() => {
                        handleChange({
                          name: "customerType",
                          value: "Advisory",
                        });
                      }}
                    />

                    <AdvisoryCustomerText>
                      Advisory customer
                    </AdvisoryCustomerText>
                    {/* <Box
                      component="img"
                      alt="Exclamation"
                      src="./images/exclamation-icon.svg"
                    ></Box> */}
                  </Box>
                  {formError.customerType && (
                    <Text variant="small" color="red" py={1}>
                      {formError.customerType}
                    </Text>
                  )}
                </Box>
              </Grid>
              <Grid item md={6}></Grid>

              <Grid item md={6}>
                <Box sx={{ width: "100%", mb: "0px" }}>
                  <JoiningDateText>Joining date</JoiningDateText>
                  <DatePicker
                    value={formData.joiningDate}
                    sx={{ width: "100%" }}
                    onChange={(d) => {
                      handleChange({ name: "joiningDate", value: d["$d"] });
                    }}
                  />
                  {formError.joiningDate && (
                    <Text variant="small" color="red" py={1}>
                      {formError.joiningDate}
                    </Text>
                  )}
                </Box>
              </Grid>
              <Grid item md={6}></Grid>

              <Grid item md={12}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="start"
                >
                  <Checkbox
                    sx={{ py: 0 }}
                    onChange={(checked) => {
                      handleChange({
                        name: "isAddUserToFamilyChecked",
                        value: checked,
                      });
                    }}
                    checked={formData.isAddUserToFamilyChecked}
                  />
                  <Text
                    sx={{
                      color: "#242424",
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                  >
                    Add user to any family group?
                  </Text>
                </Stack>
              </Grid>
              {formData.isAddUserToFamilyChecked ? (
                <Grid item md={6}>
                  <Box sx={{ width: "100%", mb: "0px" }}>
                    {/*  */}
                    <JoiningDateText>Select family group</JoiningDateText>

                    <Autocomplete
                      options={familyList}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, newValue) => {
                        handleChange({
                          name: "family",
                          value: newValue,
                        });
                      }}
                      value={formData.family || null}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Search and select family group"
                        />
                      )}
                    />

                    {formError.family && (
                      <Text variant="small" color="red" py={1}>
                        {formError.family}
                      </Text>
                    )}
                  </Box>
             
                </Grid>
              ) : (
                <></>
              )}
              <Grid item md={12}></Grid>

              <Grid item md={6}>
                <Box>
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <PanCardText>PAN Card</PanCardText>
                    <PanFormatText sx={{ mt: "2px", ml: "6px" }}>
                      {/* (PDF and Image Format only) */}
                      (PDF and Image Format only)
                    </PanFormatText>
                  </Box>
                  <FileUpload
                    name="panNo"
                    handleChange={handleChange}
                    acceptedFileTypes={[
                      "application/pdf",
                      "image/jpeg",
                      "image/jpg",
                      "image/png",
                    ]}
                  />
                  {formData.pan_card_document && (
                    <Box mt={1} sx={{ display: "flex" }}>
                      <Box
                        component="img"
                        alt="Exclamation"
                        src="./images/download.svg"
                      />

                      <Link
                        href="#"
                        underline="always"
                        sx={{
                          color: "#0C53B4",
                          fontSize: "14px",
                          fontWeight: "500px",
                        }}
                        onClick={() => downloadDocument({type: "PAN_CARD", data: docPan})}
                      >
                        {formData.pan_card_document}
                      </Link>
                    </Box>
                  )}
                  {formError.panNo && (
                    <Text variant="small" color="red" py={1}>
                      {formError.panNo}
                    </Text>
                  )}
                </Box>
              </Grid>

              <Grid item md={6}>
                <Box>
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <PanCardText>Aadhar Card/Address Proof</PanCardText>
                    <PanFormatText sx={{ mt: "2px", ml: "6px" }}>
                      {/* (PDF and Image Format only) */}
                      (PDF and Image Format only)
                    </PanFormatText>
                  </Box>
                  <FileUpload
                    name="aadharCard"
                    handleChange={handleChange}
                    acceptedFileTypes={[
                      "application/pdf",
                      "image/jpeg",
                      "image/jpg",
                      "image/png",
                    ]}
                  />
                  {formData.aadhar_card_document && (
                    <Box mt={1} sx={{ display: "flex" }}>
                      <Box
                        component="img"
                        alt="Exclamation"
                        src="./images/download.svg"
                      />

                      <Link
                        href="#"
                        underline="always"
                        sx={{
                          color: "#0C53B4",
                          fontSize: "14px",
                          fontWeight: "500px",
                        }}
                        onClick={() => downloadDocument({type: "AADHAR_CARD", data: docAdhar})}
                      >
                        {formData.aadhar_card_document}
                      </Link>
                    </Box>
                  )}

                  {formError.aadharCard && (
                    <Text variant="small" color="red" py={1}>
                      {formError.aadharCard}
                    </Text>
                  )}
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box sx={{ width: "100%" }}>
                  <PlanText>PAN Number</PlanText>
                  <TextField
                    placeholder="Enter PAN no"
                    // disabled
                    value={formData?.panNumber}
                    onChange={(e) => {
                      const input = e.target.value.toUpperCase();
                      // Allow only alphanumeric input and limit to 10 characters
                      if (/^[0-9A-Za-z]{0,10}$/.test(input)) {
                        handleChange({
                          name: "panNumber",
                          value: input,
                        });
                      }
                    }}
                    sx={{ borderRadius: "8px" }}
                  />
                  {formError.panCardNumber && (
                    <Text variant="small" color="red" py={1}>
                      {formError.panCardNumber}
                    </Text>
                  )}
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box sx={{ width: "100%" }}>
                  <PlanText>Aadhar Number</PlanText>
                  <TextField
                    placeholder=" Enter Aadhaar no"
                    sx={{ borderRadius: "8px" }}
                    value={formData?.aadharNo}
                    onChange={(e) => {
                      const input = e.target.value;
                      // Allow only numeric input
                      if (/^\d{0,12}$/.test(input)) {
                        handleChange({
                          name: "aadharNo",
                          value: input,
                        });
                      }
                    }}
                  />
                  {formError.aadharCardNumber && (
                    <Text variant="small" color="red" py={1}>
                      {formError.aadharCardNumber}
                    </Text>
                  )}
                </Box>
              </Grid>

              <Grid item md={5.8} sx={{ mb: "36px", mr: "30px" }}>
              <Box sx={{ width: "100%" }}>
                <PlanText sx={{ mb: "12px" }}>Address</PlanText>
                <TextField
                  placeholder="Enter Address"
                  // disabled
                  value={formData?.address}
                  onChange={(e) => {
                    const input = e.target.value;
                   
                      handleChange({
                        name: "address",
                        value: input,
                      });
                    
                  }}
                  sx={{ borderRadius: "8px" }}
                />
                {formError.address && (
                  <Text variant="small" color="red" py={1}>
                    {formError.address}
                  </Text>
                )}
              </Box>
            </Grid>
            
              <Grid item md={12}>
                <Box sx={{ display: "flex" }}>
                  <Link
                    onClick={downloadAgreementTemplate}
                    href="#"
                    underline="always"
                    sx={{
                      color: "#0C53B4",
                      fontSize: "14px",
                      fontWeight: "500px",
                    }}
                  >
                    {"Download Agreement"}
                  </Link>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box>
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <PanCardText>Sign agreement</PanCardText>
                    <PanFormatText sx={{ mt: "2px", ml: "6px" }}>
                      {/* (PDF and JPEG Format only) */}
                      (PDF and Image Format only)
                    </PanFormatText>
                  </Box>
                  <FileUpload
                    name="signAgreement"
                    handleChange={handleChange}
                    acceptedFileTypes={[
                      "application/pdf",
                      "image/jpeg",
                      "image/jpg",
                      "image/png",
                    ]}
                  />
                  {formData.sign_agreement && (
                    <Box mt={1} sx={{ display: "flex" }}>
                      <Box
                        component="img"
                        alt="Exclamation"
                        src="./images/download.svg"
                      />

                      <Link
                        href="#"
                        underline="always"
                        sx={{
                          color: "#0C53B4",
                          fontSize: "14px",
                          fontWeight: "500px",
                        }}
                        onClick={() => downloadDocument({type: "SIGN_AGREEMENT", data: docAgmt})}
                      >
                        {formData.sign_agreement}
                      </Link>
                    </Box>
                  )}

                  {/* {formError.signAgreement && (
                    <Text variant="small" color="red" py={1}>
                      {formError.signAgreement}
                    </Text>
                  )} */}
                </Box>
              </Grid>

              <Grid item md={6}></Grid>
            </Grid>
          </Box>
        </Card>

        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <PreviousButton
            variant="outlined"
            onClick={() => {
              handleStepChange(3);
            }}
          >
            Previous
          </PreviousButton>
          <SubmitButton onClick={handleSubmit}>Submit</SubmitButton>
        </Box>
      </Box>
    </>
  );
}

export default OtherDetails;
