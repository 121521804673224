import CustomerDetails from "components/addCustomer/CustomerDetails";
import React from "react";
import { useState } from "react";
import dayjs from "dayjs";
import CustomerOtherDetails from "./CustomerOtherDetails";
import { customerOtherDetailsApiAction } from "stores/redux/apiSlices/CustomerOtherDetailsApiSLice";
import { validationHelper } from "helpers/validation";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { fileToBase64, clickAndDownloadFile } from "helpers/fileFormat";
import { profileApiAction } from "stores/redux/riaProfile/riaProfileSlice";

import { commonActions } from "stores/redux/slices/commonSlice";

// Here we need to handle the OtherDetail component
function CustomerOtherDetailsContainer({ handleStepChange }) {
  const dispatch = useDispatch();
    const [formData, setFormData] = useState({
      // joiningDate: null,
      capital: "",
      isCustomInvestmentSelected: null,
      isAlgoSelected: null,
      isModelPortfolioSelected: null,
      customerType: "Advisory",
      customerResidency: null,
      customerCategory: null,
      panNo: null,
      aadharCard: null,
      panCardNo: null,
      address: null,
      aadharNo: "",
      signAgreement: null,
    //   panNumber: null,
    //   isAddUserToFamilyChecked: false,
    // family: null,
    // isAutoCheck: false,
    // pan_card_document
  });

  const [formError, setFormError] = useState({

    // joiningDate: null,
    capital: null,
    isCustomInvestmentSelected: null,
    isAlgoSelected: null,
    isModelPortfolioSelected: null,
    customerResidency: null,
    customerCategory: null,
    customerType: null,
    panNo: null,
    aadharCard: null,
    panCardNo: null,
    aadharNo: null,
    address: null
    // signAgreement: null,
    // family: null,
    // panCardNumber: null,
  });
	const navigate = useNavigate();
  const handleChange = ({ name, value }) => {
    let newFormData = { ...formData };
    newFormData[name] = value;
    setFormData(newFormData);
  };
  const userId = useSelector((state)=> state?.customerOnboarding?.userId);

  // Apis
  const [fetchSignAgreementDoc] = profileApiAction.getLazySignAgreementApi();

  const userDetails = useSelector((state) => state.commonReducer.userDetails);

  const handleSubmit = async () => {
    if (!handleValidation()) {
      return;
    }
  
    try {
      let panString, aadharString, signAgreementString;
  
      const getBase64String = async (file) => {
        if (Array.isArray(file)) {
          file = file[0];
        }
        const base64 = await fileToBase64(file);
        let fileType = file.type;
  
        // Change the MIME type from "image/jpeg" to "application/jpeg"
        if (fileType === "image/jpeg") {
          fileType = "application/jpeg";
        }

        if (fileType === "image/png") {
          fileType = "application/png";
        }
  
        return { base64, fileType };
      };
  
      if (formData.panNo) {
        const { base64, fileType } = await getBase64String(formData.panNo);
        panString = `data:${fileType};base64,${base64}`;
      }
  
      if (formData.aadharCard) {
        const { base64, fileType } = await getBase64String(formData.aadharCard);
        aadharString = `data:${fileType};base64,${base64}`;
      }
  
      if (formData.signAgreement) {
        const { base64, fileType } = await getBase64String(formData.signAgreement);
        signAgreementString = `data:${fileType};base64,${base64}`;
      } else {
        signAgreementString = '';
      }
  
      const products = [];
  
      if (formData.isCustomInvestmentSelected) {
        products.push({
          product_id: formData.isCustomInvestmentSelected
        });
      }
  
      if (formData.isModelPortfolioSelected) {
        products.push({
          product_id: formData.isModelPortfolioSelected
        });
      }
  
      if (formData.isAlgoSelected) {
        products.push({
          product_id: formData.isAlgoSelected
        });
      }
  
      const payload = {
        userId: userDetails.user_id,
        otherDetails: {
          capital: formData.capital,
          products: products,
          customer_type: formData.customerType,
          customer_residency: formData.customerResidency,
          customer_category: formData.customerCategory,
          pan_card: panString || '',
          aadhar_card: aadharString || '',
          pan_card_nbr: formData.panCardNo,
          aadhar_card_nbr: formData.aadharNo,
          user_address: formData.address,
          is_auto_trade: "N",
          sign_agreement: signAgreementString || ''
        }
      };
  
      const res = await submitOtherDetails(payload);
      dispatch(commonActions.setOnboardStatus({status: "PENDING", stage: 3}));
  
      if (res.data.status === 1) {
        navigate("/customer/blank");
        handleStepChange(5);
      }
    } catch (err) {
      console.log(err);
    }
  };
  

//   const handleSubmit = async () => {

//     if (!handleValidation()) {
//       return;
//     }
  
//     try {
//       let panString, aadharString, signAgreementString;
  
//       if (Array.isArray(formData.panNo)) {
//         panString = await fileToBase64(formData.panNo[0]);
//       } else {
//         panString = await fileToBase64(formData.panNo);
//       }
  
//       if (Array.isArray(formData.aadharCard)) {
//         aadharString = await fileToBase64(formData.aadharCard[0]);
//       } else {
//         aadharString = await fileToBase64(formData.aadharCard);
//       }
  
//       // if (Array.isArray(formData.signAgreement)) {
//       //   signAgreementString = await fileToBase64(formData.signAgreement[0]);
//       // } else {
//       //   signAgreementString = await fileToBase64(formData.signAgreement);
//       // } 


      
//       const products = [];

// if (formData.isCustomInvestmentSelected) {
//   products.push({
//     product_id: formData.isCustomInvestmentSelected
//   });
// }

// if (formData.isModelPortfolioSelected) {
//   products.push({
//     product_id: formData.isModelPortfolioSelected
//   });
// }

// if (formData.isAlgoSelected) {
//   products.push({
//     product_id: formData.isAlgoSelected
//   });
// }
//       const payload = {
//         userId: userDetails.user_id,

//         otherDetails: {
//           // joining_date: formData.joiningDate ? dayjs(formData.joiningDate).format("YYYY/MM/DD") : '',
//           capital: formData.capital,
//           products: products,
//           customer_type: formData.customerType,
//           customer_residency: formData.customerResidency,
//           customer_category: formData.customerCategory,
//           pan_card: `data:application/pdf;base64,${panString}`,
//           aadhar_card: `data:application/pdf;base64,${aadharString}`,
//           pan_card_nbr: formData.panCardNo,
//           aadhar_card_nbr: formData.aadharNo,
//           is_auto_trade : "N",
//           // sign_agreement: `data:application/pdf;base64,${signAgreementString}`,
//         },
        
//       };

//       if (formData.signAgreement) {
//         if (Array.isArray(formData.signAgreement)) {
//           signAgreementString = await fileToBase64(formData.signAgreement[0]);
//         } else {
//           signAgreementString = await fileToBase64(formData.signAgreement);
//         }
//         payload.otherDetails.sign_agreement = `data:application/pdf;base64,${signAgreementString}`;
//       } else {
//         // If sign agreement is not uploaded, include an empty string in the payload
//         payload.otherDetails.sign_agreement = '';
//       }

//       const res = await submitOtherDetails(payload);
//       dispatch(commonActions.setOnboardStatus({status: "PENDING", stage: 3}));

//       if (res.data.status === 1) {
//         navigate("/customer/blank");
//         handleStepChange(5);
//       }
//     } catch (err) {
//       console.log(err);
//     }
//   };

  const handleValidation = () => {
    const newFormError = { ...formError };
    // const joiningDateValidation = validationHelper.required(formData.joiningDate);
    // newFormError.joiningDate = joiningDateValidation.message;

    const capitalValidation = validationHelper.required(formData.capital);
    newFormError.capital = capitalValidation.message;

    const invesmentTypeValidation = validationHelper.required(formData.isCustomInvestmentSelected ||formData.isAlgoSelected || formData.isModelPortfolioSelected);
    newFormError.isCustomInvestmentSelected = invesmentTypeValidation.message;

    const customerTypeValidation = validationHelper.required(formData.customerType);
    newFormError.customerType = customerTypeValidation.message;

    const customerResidencyValidation = validationHelper.required(formData.customerResidency);
    newFormError.customerResidency = customerResidencyValidation.message;

    const customerCategoryValidation = validationHelper.required(formData.customerCategory);
    newFormError.customerCategory = customerCategoryValidation.message;

    let panValidation = { isValid: true };
    if (!formData.panNo) {
      panValidation = validationHelper.required(formData.panNo);
      newFormError.panNo = panValidation.message;
    }else{
      newFormError.panNo = null;
    }

    let aadhaarValidation = { isValid: true };
    if (!formData.aadharCard) {
      aadhaarValidation = validationHelper.required(formData.aadharCard);
      newFormError.aadharCard = aadhaarValidation.message;
    }else{
      newFormError.aadharCard = null;
    }

    const panNumberValidation = validationHelper.pan(formData.panCardNo);
    newFormError.panCardNo = panNumberValidation.message;

    const aadhardCardNumberValidation = validationHelper.aadhar(formData.aadharNo);
    newFormError.aadharNo = aadhardCardNumberValidation.message;

    const addressValidation = validationHelper.required(formData.address);
    newFormError.address = addressValidation.message;


    setFormError(newFormError);

    return (
      // joiningDateValidation.isValid &&
      capitalValidation.isValid &&
      invesmentTypeValidation.isValid &&
      customerTypeValidation.isValid &&
      customerResidencyValidation.isValid &&
      customerCategoryValidation.isValid &&
      panValidation.isValid &&
      aadhaarValidation.isValid &&
      aadhardCardNumberValidation.isValid &&
      panNumberValidation.isValid &&
      addressValidation.isValid
    );
  };

  const downloadAgreementTemplate = async () => {
    let userId = userDetails.user_id;
    let res = await fetchSignAgreementDoc({userId});
    if (res.data.file){
      const { file, filename } = res.data;
      await clickAndDownloadFile({ base64File: file, fileName: filename });
    }
  };

  const [submitOtherDetails] =  customerOtherDetailsApiAction.updateOtherDetails();
  return (
    <>
      <CustomerOtherDetails
        formData={formData}
        handleStepChange={handleStepChange}
        handleChange={handleChange}
        formError={formError}
        handleSubmit={handleSubmit}
        downloadAgreementTemplate={downloadAgreementTemplate}
      />
    </>
  );
}

export default CustomerOtherDetailsContainer;
